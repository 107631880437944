import React from 'react';
import { ChromePicker } from 'react-color'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import { prp_template_api, crm_api, prp_roles_api_bulk_update } from '../../config/apiList';
import { RotateCircleLoading } from 'react-loadingg';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import AutcompleteInput from '../../shared/AutocompleteInput/Autocomplete';
import moment from 'moment';
// import { weekStartDates } from '../../utils/betweenDates';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Auth from '../../utils/authenticate';
const token = Auth.token()

const useStyles = (theme) => ({
    root: {
        '& > *': {
            width: '100%',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    date: {
        margin: 0,
        width: '100%',
        'margin-top': '5px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CRMEdit extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: false,
            account_name: '',
            bb_project_value: null,
            bid_resource: '',
            contract_award_value: null,
            contract_no: '',
            contractor_end_date: null,
            contractor_start_date: null,
            date_created: null,
            description: null,
            expected_award_date: null,
            gate_level_acheived: null,
            id: '',
            itt_due_date: null,
            itt_start_date: null,
            location: '',
            market_sub_vertical: '',
            name: '',
            opportunity_id: '',
            opportunity_type: '',
            owner_delivery: '',
            owner_region_sector: '',
            sales_lead: '',
            sales_stage: '',
            sales_status: null,
            scope_of_work: '',
            start_on_site_date: null,
            sub_sector: '',
            county: '',
            submission_margin: null,
            tender_submit_date: null,
            dateChangeDialog: false,
            daysShift: 0,
            ittDaysDiff: 0,
            empUpdateCount: 0,
            refreshCRM: false,
            project_code: '',
            color_code: '#FFF',
            w3w: '',
            eci_start_date: null,
            eci_end_date: null,
            stage1_start_date: null,
            stage1_end_date: null,
            stage2_start_date: null,
            stage2_end_date: null,
        }
    }

    componentDidMount() {
        if (this.props.type === 'edit') {
            let proj = this.props.project
            this.oldData = proj
            this.setState({ isLoading: true })
            this.setState(prev => {
                return { ...prev, ...proj }
            }, () => {
                this.setState({ isLoading: false })
            })
            this.setState(prev => {
                return {
                    gate_level_acheived: this.props.gateLevelArr.find(e => e.id === prev.gate_level_acheived),
                    account_name: this.props.accountNameArr.find(e => e.id === prev.account_name),
                    sales_stage: this.props.salesStageArr.find(e => e.id === prev.sales_stage),
                    sales_status: this.props.salesStatusArr.find(e => e.id === prev.sales_status),
                    sub_sector: this.props.subSectorArr.find(e => e.id === prev.sub_sector),
                    market_sub_vertical: this.props.marketSubVerticalArr.find(e => e.id === prev.market_sub_vertical),
                    owner_delivery: this.props.ownerDeliveryArr.find(e => e.id === prev.owner_delivery),
                    owner_region_sector: this.props.ownerResionArr.find(e => e.id === prev.owner_region_sector),
                    opportunity_type: this.props.opportunityTypeArr.find(e => e.id === prev.opportunity_type),
                    county: this.props.countyList.find(e => e.id === this.props.project.county),
                }
            })
        }
    }


    // fillFormData
    handleChange = (e) => {
        let { id, value } = e.target;
        this.setState({ [id]: value }, () => {
            console.log(this.state)
        })
    }

    setAutoCompleteInput(id, value) {
        this.setState({ [id]: value })
    }

    setAutoCompleteInputCounty(id, value) {
        this.setState({ [id]: value })
    }

    putOps(data) {
        this.setState({ isLoading: true })
        Service.put(crm_api + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data,
        })
            .then(res => {
                // this.getCRMTableData()
                this.props.enqueueSnackbar('Opportunity updated', {
                    variant: 'success'
                });
                this.setState({ isLoading: false, refreshCRM: true })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            });
    }

    saveChanges() {
        let oldData = this.oldData;
        let newData = { ...this.state }
        if (!newData.opportunity_id) {
            this.props.enqueueSnackbar('Opportunity id is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!newData.name) {
            this.props.enqueueSnackbar('Opportunity name is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!newData.contractor_start_date) {
            this.props.enqueueSnackbar('Contract start date is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!newData.contractor_end_date) {
            this.props.enqueueSnackbar('Contract end date is required', {
                variant: 'warning'
            });
            return;
        }
        newData.bb_project_value = newData.bb_project_value ? newData.bb_project_value : null;
        newData.contract_award_value = newData.contract_award_value ? newData.contract_award_value : null;
        newData.submission_margin = newData.submission_margin ? newData.submission_margin : null;


        newData.gate_level_acheived = newData.gate_level_acheived ? newData.gate_level_acheived.id : null;
        newData.account_name = newData.account_name ? newData.account_name.id : null;
        newData.sales_stage = newData.sales_stage ? newData.sales_stage.id : null;
        newData.sales_status = newData.sales_status ? newData.sales_status.id : null;
        newData.sub_sector = newData.sub_sector ? newData.sub_sector.id : null;
        newData.market_sub_vertical = newData.market_sub_vertical ? newData.market_sub_vertical.id : null;
        newData.owner_delivery = newData.owner_delivery ? newData.owner_delivery.id : null;
        newData.owner_region_sector = newData.owner_region_sector ? newData.owner_region_sector.id : null;
        newData.opportunity_type = newData.opportunity_type ? newData.opportunity_type.id : null;
        newData.contractor_end_date = moment(newData.contractor_end_date).format();
        newData.contractor_start_date = moment(newData.contractor_start_date).format();
        newData.color_code = newData.color_code ? newData.color_code : null;
        newData.county = newData.county ? newData.county.id : null;
        newData.eci_start_date = newData.eci_start_date ? moment(newData.eci_start_date).format() : null;
        newData.eci_end_date = newData.eci_end_date ? moment(newData.eci_end_date).format() : null;
        newData.stage1_start_date = newData.stage1_start_date ? moment(newData.stage1_start_date).format() : null;
        newData.stage1_end_date = newData.stage1_end_date ? moment(newData.stage1_end_date).format() : null;
        newData.stage2_start_date = newData.stage2_start_date ? moment(newData.stage2_start_date).format() : null;
        newData.stage2_end_date = newData.stage2_end_date ? moment(newData.stage2_end_date).format() : null;

        setTimeout(() => {
            let sameStartDate
            if (!oldData.contractor_start_date || !newData.contractor_start_date) {
                sameStartDate = true
            }
            else {
                sameStartDate = moment(oldData.contractor_start_date).isSame(moment(newData.contractor_start_date).format());
            }
            // const sameEndDate = moment(oldData.contractor_end_date).isSame(newData.contractor_end_date);
            let sameITTStartDate;
            if (!oldData.itt_start_date || !newData.itt_start_date) {
                sameITTStartDate = true
            }
            else {
                sameITTStartDate = moment(oldData.itt_start_date).isSame(newData.itt_start_date)
            }
            if (sameStartDate === true && sameITTStartDate === true) {
                this.putOps(newData)
            }
            else {
                Service
                    .get(prp_template_api + "?crm=" + newData.id, {
                        headers: {
                            Authorization: "Token " + token,
                        },
                    })
                    .then(res => {
                        // if (data.length > 0) {
                        //     // this.shiftDate(data, oldData, newData, sameStartDate, sameITTStartDate)
                        // }
                        // else {
                        this.putOps(newData)
                        // }
                    })
                    .catch(err => {
                        this.props.enqueueSnackbar('Something went wrong.', {
                            variant: 'error'
                        });
                    })
            }

        }, 1000)
    }


    dateChangeDialogClose = () => {
        this.setState({ dateChangeDialog: false })
    }

    async continueUdate() {
        // this.setState({ isLoading: true })
        this.setState({ isLoading: true })
        this.dateChangeDialogClose()
        let res = await this.bulkEmployeeUpdate(this.updatedEmployees)
        if (res.success === true) {
            this.putOps(this.newData)
        }
        else {
            this.setState({ isLoading: false })
        }
    }

    bulkEmployeeUpdate(data) {
        return new Promise((resolve, reject) => {
            Service.patch(prp_roles_api_bulk_update, {
                headers: {
                    Authorization: "Token " + token,
                },
                data
            })
                .then(res => {
                    this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully updated', {
                        variant: 'success'
                    });

                    resolve({ success: true })
                })
                .catch(e => {
                    console.error(e)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    reject({ success: false })
                })
        })
    }

    addToCRM() {
        let project = { ...this.state }

        if (!project.opportunity_id) {
            this.props.enqueueSnackbar('Opportunity id is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!project.name) {
            this.props.enqueueSnackbar('Opportunity name is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!project.contractor_start_date) {
            this.props.enqueueSnackbar('Contract start date is required', {
                variant: 'warning'
            });
            return;
        }
        else if (!project.contractor_end_date) {
            this.props.enqueueSnackbar('Contract start end is required', {
                variant: 'warning'
            });
            return;
        }
        project.bb_project_value = project.bb_project_value ? project.bb_project_value : 0;
        project.contract_award_value = project.contract_award_value ? project.contract_award_value : 0;
        project.submission_margin = project.submission_margin ? project.submission_margin : 0;
        let obj = {
            opportunity_id: project.opportunity_id,
            contract_no: project.contract_no,
            name: project.name,
            project_code: project.project_code,
            gate_level_acheived: project.gate_level_acheived ? project.gate_level_acheived.id : null,
            itt_due_date: project.itt_due_date,
            tender_submit_date: project.tender_submit_date,
            contractor_start_date: project.contractor_start_date,
            contractor_end_date: project.contractor_end_date,
            account_name: project.account_name ? project.account_name.id : null,
            sales_stage: project.sales_stage ? project.sales_stage.id : null,
            sales_status: project.sales_status ? project.sales_status.id : null,
            tender_value: project.tender_value,
            bb_project_value: project.bb_project_value,
            itt_start_date: project.itt_start_date,
            sales_lead: project.sales_lead,
            expected_award_date: project.expected_award_date,
            sub_sector: project.sub_sector ? project.sub_sector.id : null,
            scope_of_work: project.scope_of_work,
            submission_margin: project.submission_margin,
            market_sub_vertical: project.market_sub_vertical ? project.market_sub_vertical.id : null,
            owner_delivery: project.owner_delivery ? project.owner_delivery.id : null,
            owner_region_sector: project.owner_region_sector ? project.owner_region_sector.id : null,
            description: project.description,
            opportunity_type: project.opportunity_type ? project.opportunity_type.id : null,
            location: project.location,
            start_on_site_date: project.start_on_site_date,
            contract_award_value: project.contract_award_value,
            latitude: project.latitude,
            longitude: project.longitude,
            w3w: project.w3w,
            county: project.county ? project.county.id : null,
            postal_code: project.postal_code,
            city: project.city,
            color_code: project.color_code,
            eci_start_date: project.eci_start_date,
            eci_end_date: project.eci_end_date,
            stage1_start_date: project.stage1_start_date,
            stage1_end_date: project.stage1_end_date,
            stage2_start_date: project.stage2_start_date,
            stage2_end_date: project.stage2_end_date,
        }
        this.postCRMData(obj)
    }

    postCRMData(data) {
        this.setState({ isLoading: true })
        Service.post(crm_api, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data,
        })
            .then(res => {
                this.setState({ isLoading: false, refreshCRM: true }, () => {
                    this.props.onClose(this.state.refreshCRM)
                })
                this.props.enqueueSnackbar('Opportunity successfully added', {
                    variant: 'success'
                });
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    render() {
        const date_margin = {
            margin: 0,
            width: '100%'
        }
        const proj = this.props.project;
        const {
            open,
            onClose,
            classes,
            gateLevelArr,
            accountNameArr,
            salesStageArr,
            subSectorArr,
            marketSubVerticalArr,
            ownerDeliveryArr,
            ownerResionArr,
            opportunityTypeArr,
            salesStatusArr,
            countyList
        } = this.props
        return (<>

            <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>

                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => onClose(this.state.refreshCRM)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {this.props.type === 'edit' ? 'Edit - ' + proj.name : 'Add Opportunity'}
                        </Typography>
                        {this.props.type === 'edit' ?
                            <Button variant="contained" onClick={this.saveChanges.bind(this)}>
                                Save Changes
                            </Button>
                            :
                            <Button variant="contained" onClick={this.addToCRM.bind(this)}>
                                Add to CRM
                            </Button>
                        }
                    </Toolbar>
                </AppBar>
                {this.state.isLoading ?
                    <RotateCircleLoading color="#005D99" /> :
                    <div style={{ padding: '25px', marginTop: '65px' }}>
                        <Grid container spacing={3} style={{ marginBottom: '20px' }}>

                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    id="opportunity_id"
                                    label="Opportunity Id"
                                    fullWidth
                                    onChange={this.handleChange}
                                    value={this.state.opportunity_id}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <TextField
                                    id="contract_no"
                                    label="Contract No"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={this.handleChange}
                                    value={this.state.contract_no}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                    id="name"
                                    label="Opportunity Name"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <TextField
                                    id="project_code"
                                    label="Project Code"
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={this.handleChange}
                                    value={this.state.project_code}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <AutcompleteInput
                                    id="gate_level_acheived"
                                    label="Gate Level Achieved"
                                    options={gateLevelArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('gate_level_acheived', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.gate_level_acheived}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker className={date_margin}
                                        // disableToolbar
                                        // variant="inline"
                                        autoOk
                                        clearable
                                        animateYearScrolling
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="itt_due_date"
                                        label="ITT Due Date"
                                        value={this.state.itt_due_date}
                                        onChange={(date) => this.setState({ itt_due_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}

                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        // disableToolbar
                                        // variant="inline"
                                        autoOk
                                        clearable
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="tender_submit_date"
                                        label="Tender Submit Date"
                                        value={this.state.tender_submit_date}
                                        onChange={(date) => this.setState({ tender_submit_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        // disableToolbar
                                        // variant="inline"
                                        autoOk
                                        clearable
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="contractor_start_date"
                                        label="Contract Start Date"
                                        value={this.state.contractor_start_date}
                                        onChange={(date) => this.setState({ contractor_start_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        // disableToolbar
                                        // variant="inline"
                                        autoOk
                                        clearable
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="contractor_end_date"
                                        label="Contract End Date"
                                        value={this.state.contractor_end_date}
                                        onChange={(date) => this.setState({ contractor_end_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="account_name"
                                    label="Client Name"
                                    options={accountNameArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('account_name', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.account_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="sales_stage"
                                    label="Sales Stage"
                                    options={salesStageArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('sales_stage', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.sales_stage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="sales_status"
                                    label="Distribution Type"
                                    options={salesStatusArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('sales_status', v)}
                                    optionLabel={option => option.name || ''}
                                    value={this.state.sales_status}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="tender_value"
                                    label="Tender Value"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.tender_value}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="bb_project_value"
                                    label="BB Project Value"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.bb_project_value}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        // disableToolbar
                                        autoOk
                                        clearable
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="itt_start_date"
                                        label="ITT Start Date"
                                        value={this.state.itt_start_date}
                                        onChange={(date) => this.setState({ itt_start_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="expected_award_date"
                                        label="Expected Award Date"
                                        value={this.state.expected_award_date}
                                        onChange={(date) => this.setState({ expected_award_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="sales_lead"
                                    label="Sales Lead"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.sales_lead}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="sub_sector"
                                    // label="Sub Sector"
                                    label="Discipline"
                                    options={subSectorArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('sub_sector', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.sub_sector}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="submission_margin"
                                    label="Submission Margin %"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.submission_margin}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="market_sub_vertical"
                                    label="Division"
                                    options={marketSubVerticalArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('market_sub_vertical', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.market_sub_vertical}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="owner_delivery"
                                    label="Operations Lead"
                                    options={ownerDeliveryArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('owner_delivery', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.owner_delivery}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="owner_region_sector"
                                    label="Sub Division"
                                    options={ownerResionArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('owner_region_sector', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.owner_region_sector}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <AutcompleteInput
                                    id="opportunity_type"
                                    label="Opportunity Type"
                                    options={opportunityTypeArr}
                                    onChange={(e, v) => this.setAutoCompleteInput('opportunity_type', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.opportunity_type}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="location"
                                    label="Location"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.location}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="start_on_site_date"
                                        label="Start on Site Date"
                                        value={this.state.start_on_site_date}
                                        onChange={(date) => this.setState({ start_on_site_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="contract_award_value"
                                    label="Contract Award Value"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.contract_award_value}
                                    type="number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="scope_of_work"
                                    label="Scope of Work"
                                    value={this.state.scope_of_work}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 2000,
                                    }}
                                    helperText={'Character remaining : ' + (this.state.scope_of_work ? (2000 - this.state.scope_of_work.length).toFixed(0) : 2000)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="description"
                                    label="Description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <TextField
                                    id="city"
                                    label="City"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.city}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <AutcompleteInput
                                    id="county"
                                    label="County"
                                    options={countyList}
                                    onChange={(e, v) => this.setAutoCompleteInputCounty('county', v)}
                                    optionLabel={option => option.name}
                                    value={this.state.county}
                                />
                            </Grid>
                            {this.props.type === 'edit' ?
                                <Grid item xs>
                                    <TextField
                                        id="w3w"
                                        label="W3W"
                                        onChange={this.handleChange}
                                        variant="outlined"
                                        disabled={this.props.type === 'edit'}
                                        fullWidth={true}
                                        value={this.state.w3w}
                                    />
                                </Grid> : null}
                            <Grid item xs={this.props.type === 'edit' ? 2 : 3}>
                                <TextField
                                    id="latitude"
                                    label="Latitude"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.latitude}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={this.props.type === 'edit' ? 2 : 3}>
                                <TextField
                                    id="longitude"
                                    label="Longitude"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.longitude}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    id="postal_code"
                                    label="Postal Code"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth={true}
                                    value={this.state.postal_code}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="eci_start_date"
                                        label="ECI Start Date"
                                        value={this.state.eci_start_date}
                                        onChange={(date) => this.setState({ eci_start_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="eci_end_date"
                                        label="ECI End Date"
                                        value={this.state.eci_end_date}
                                        onChange={(date) => this.setState({ eci_end_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage1_start_date"
                                        label="Stage 1 Start Date"
                                        value={this.state.stage1_start_date}
                                        onChange={(date) => this.setState({ stage1_start_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage1_end_date"
                                        label="Stage 1 End Date"
                                        value={this.state.stage1_end_date}
                                        onChange={(date) => this.setState({ stage1_end_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage2_start_date"
                                        label="Stage 2 Start Date"
                                        value={this.state.stage2_start_date}
                                        onChange={(date) => this.setState({ stage2_start_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={2}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker className={date_margin}
                                        autoOk
                                        clearable
                                        // disableToolbar
                                        format="dd/MM/yyyy"
                                        margin="normal"
                                        id="stage2_end_date"
                                        label="Stage 2 End Date"
                                        value={this.state.stage2_end_date}
                                        onChange={(date) => this.setState({ stage2_end_date: date })}
                                        fullWidth={true}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container direction="column"
                            justifyContent="center"
                            alignItems="start" >
                            <Typography>{'Select project color'}</Typography>
                            {/* <ChromePicker /> */}
                            <ChromePicker color={this.state.color_code ? this.state.color_code : ''} onChange={updatedColor => this.setState({ color_code: updatedColor.hex })} />
                            {/* <TextField id="color" value={this.state.color} onChange={this.handleChange} style={{ marginLeft: '5rem', color: 'white', backgroundColor: this.state.color, borderRadius: '2px', width: '5rem', height: '2rem', alignText: 'center' }} /> */}
                        </Grid>
                        {/* <Divider /> */}

                        <Dialog
                            open={this.state.dateChangeDialog}
                            onClose={this.dateChangeDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Continue to change Contract Start/End Date?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Start and End Date change will shift all added employee schedule dates according to the difference to new Start Date.
                                    <br /><br />
                                    {this.state.empUpdateCount}&ensp;employees schedule dates will be updated.
                                    <br />
                                    Contract Start Date shift - {this.state.daysShift} days
                                    <br />
                                    ITT Start Date shift - {this.state.ittDaysDiff} days
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.dateChangeDialogClose} color="secondary">
                                    Cancel
                                </Button>
                                <Button onClick={this.continueUdate.bind(this)} color="primary" autoFocus>
                                    Continue
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </div>
                }
            </Dialog>
        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(CRMEdit))