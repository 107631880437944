import React from 'react';
import moment from 'moment';
import { withSnackbar } from "notistack";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
// import EmployeeRow from "../Reports/ResourceLocation/EmployeeRow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { filterOneObjArrayByAnoterObjArray } from '../../utils/reuseFunctions';
import { LoopCircleLoading } from 'react-loadingg';
import InputAdornment from '@material-ui/core/InputAdornment';
import { weekStartDates } from '../../utils/betweenDates';
import SubContractorRow from './SubContractorRow';

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  root: {
    width: 'calc(100vw - 35px)',
  },
  container: {
    maxHeight: 410,
  },
  topSticky: {
    position: "sticky",
    top: '0px',
    background: " #F8F8F8",
    boxShadow: "5px 2px 5px grey"
  },
  topStickyDates: {
    position: "sticky",
    top: '68px',
    background: " #F8F8F8",
    boxShadow: "5px 2px 5px grey"
  },
  smallIcon: {
    padding: '5px'
  }
});

const months = { 0: 'January', 1: 'Febrauary', 2: 'March', 3: 'April', 4: 'May', 5: 'June', 6: 'July', 7: 'August', 8: 'September', 9: 'October', 10: 'November', 11: 'December' }
class DisplaySubontractor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allRoles: [],
      rolesObj: {},
      allEmp: [],
      empObj: {},
      columns: [],
      allMonths: [],
      selectedEndDate: !this.props.selectedStartDate ? null : this.props.selectedEndDate,
      selectedStartDate: !this.props.selectedEndDate ? null : this.props.selectedStartDate,
      templateRoles: [],
      selectedEmployee: "",
      employeeSearchField: "",
      roleSearchField: "",
      rawEmployeeSchedules: [],
      tabcelEdit: { empIndex: null, weekIndex: 0, showInputBox: false },
      editedWeekObj: {},
      searchLoading: false,
    }
    this.handleRefresh = this.handleRefresh.bind(this)
    this.addAllocation = this.addAllocation.bind(this)
  }

  handleRefresh() {
    this.props.refreshData()
  }

  componentDidMount() {
    // console.log(this.props)
    let { allEmpCopy, allEmp, columns, allMonths, liveOpportunities } = this.props.roleData;
    this.setState({ templateRoles: this.props.templateRoles, allEmpCopy, allEmp, columns, allMonths, liveOpportunities })
  }

  makeReport() {
    this.setState({ searchLoading: true })
    let templateRoles = filterOneObjArrayByAnoterObjArray(this.props.templateRoles, this.props.liveOpportunities, 'crm', 'id')
    const startOfMonth = this.state.selectedStartDate ? moment(this.state.selectedStartDate).startOf('month') : moment().subtract(2, 'months').startOf('month');
    const endOfMonth = this.state.selectedEndDate ? moment(this.state.selectedEndDate).endOf('month') : moment().add(6, 'months').endOf('month');

    let startDate = moment(startOfMonth)//moment('2012-04-01');
    let endDate = moment(endOfMonth)//moment('2014-11-01');

    let result = [];

    if (endDate.isBefore(startDate)) {
      throw new Error("End date must be greated than start date.")
    }

    while (startDate.isBefore(endDate)) {
      result.push(months[startDate.month()] + ' ' + startDate.year());
      startDate.add(1, 'month');
    }

    let allWeekDates = weekStartDates(startOfMonth, endOfMonth);


    let final = []
    let colspan = 1;
    for (let i = 0; i < allWeekDates.length; i++) {

      let date = moment(allWeekDates[i], 'DD/MM/YYYY')
      let monthYear = months[date.month()] + ' ' + date.year()
      if (!result.length) {
        result.push(monthYear)
        final.push({ month: monthYear, colSpan: colspan })
      }
      else {
        if (result[result.length - 1] === monthYear) {
          colspan += 1
          final[final.length - 1]['colSpan'] = colspan
        }
        else {
          colspan = 1
          result.push(monthYear)
          final.push({ month: monthYear, colSpan: colspan })
        }
      }
    }

    // let empObj = {}
    let filterEmp = []
    for (let i = 0; i < this.state.allEmp.length; i++) {
      // let newData = { [this.state.allEmp[i].id]: this.state.allEmp[i].first_name + ' ' + this.state.allEmp[i].last_name }
      // empobj = { ...empobj, ...newData }
      let newEle = this.state.allEmp[i]
      let weeks = [];
      let weekdata = []
      for (let j = 0; j < templateRoles.length; j++) {
        // if (this.state.allEmp[i].job_title === this.props.data.id && templateRoles[j].role_id === this.props.data.id) {
        // allWeekDates.forEach(date => {
        for (let m = 0; m < allWeekDates.length; m++) {
          let date = allWeekDates[m];
          if (this.state.allEmp[i].id === templateRoles[j].employee_id) {
            // if (templateRoles[j].dates) {
            if (templateRoles[j].dates.hasOwnProperty(date) && templateRoles[j].dates[date] !== 0) {
              if (weeks.indexOf(date) === -1) {
                newEle = { ...newEle, [date]: templateRoles[j].crm }
                weeks.push(date);
                weekdata.push({ name: [templateRoles[j].crm__name], project_code: [this.props.projectObj[templateRoles[j].crm_id]['project_code']], crmId: [templateRoles[j].crm_id], crm_color_code: [templateRoles[j].crm__color_code], })
              } else {
                // If already a project in the week then concatenate the date
                if (weekdata[weeks.indexOf(date)]['name'] !== "") {
                  weekdata[weeks.indexOf(date)]['name'].push(templateRoles[j].crm__name)
                  weekdata[weeks.indexOf(date)]['project_code'].push(this.props.projectObj[templateRoles[j].crm_id]['project_code'] ? this.props.projectObj[templateRoles[j].crm_id]['project_code'] : this.props.projectObj[templateRoles[j].crm_id]['crm_name'])
                  weekdata[weeks.indexOf(date)]['crmId'].push(templateRoles[j].crm)
                  weekdata[weeks.indexOf(date)]['crm_color_code'].push(templateRoles[j].crm__color_code)
                  weekdata[weeks.indexOf(date)]['leaves'] = templateRoles[j].emp_leave
                  // weekdata[weeks.indexOf(date)] +
                  // "\n , \n" +
                  // this.props.projectObj[templateRoles[j].crm];

                } else if (weekdata[weeks.indexOf(date)]['name'] === "") {
                  weekdata[weeks.indexOf(date)]['name'].push(this.props.projectObj[templateRoles[j].crm__name])
                  weekdata[weeks.indexOf(date)]['project_code'].push(this.props.projectObj[templateRoles[j].crm_id]['project_code'] ? this.props.projectObj[templateRoles[j].crm_id]['project_code'] : this.props.projectObj[templateRoles[j].crm_id]['crm_name'])
                  weekdata[weeks.indexOf(date)]['crmId'].push(templateRoles[j].crm_id)
                  weekdata[weeks.indexOf(date)]['crm_color_code'].push(templateRoles[j].crm__color_code)
                  weekdata[weeks.indexOf(date)]['leaves'] = templateRoles[j].emp_leave
                  // weekdata[weeks.indexOf(date)] =
                  //   weekdata[weeks.indexOf(date)] +
                  //   this.props.projectObj[templateRoles[j].crm];
                }
              }
            }
            else {
              newEle = { ...newEle, [date]: '' }
              weeks.push(date);
              weekdata.push({ name: [], crmId: [], project_code: [], crm_color_code: [], leaves: {} })
            }
            // }
          }

        }
        // }
      }
      newEle['weeks'] = weeks;
      newEle['weekdata'] = weekdata
      // if (this.state.allEmp[i].job_title === this.props.data.id && newEle.weekdata.length > 0) {
      if (newEle.weekdata.length > 0) {
        let notEmpty = newEle.weekdata.filter((week => week['name'].length > 0))
        // if all weekdata strings are empty then fiter it out
        // if (notEmpty.length > 1 && moment(newEle.end_date).isAfter(moment().format())) {
        if (notEmpty.length > 1 && moment(newEle.end_date).isAfter(moment().format())) {
          // if (notEmpty.length > 1){
          filterEmp.push(newEle)
        }
      }
    }

    let columns = []
    // columns.push({ title: 'Employee', field: 'id', lookup: empobj })
    for (let i = 0; i < allWeekDates.length; i++) {
      let newObj = {
        title: moment(allWeekDates[i], 'DD/MM/YYYY').format('DD MMM'),
        field: allWeekDates[i],
        type: 'number',
        width: 150,
        sorting: false,
        editable: 'never',
        filtering: false
      }
      columns.push(newObj)
    }
    this.setState({ allEmpCopy: filterEmp, allEmp: filterEmp, columns, allMonths: final, }, () => {
      console.log(this.state.allEmp)
      setTimeout(() => {
        this.setState({ searchLoading: false })
      }, 100);
    })
  }

  filterByName = (e) => {
    let allEmps = [...this.state.allEmp];

    let filteredByEmployees;

    filteredByEmployees = allEmps.filter(
      (emp) =>
        emp.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) 
    );

    if (e.target.value === "") {
      this.setState({
        allEmp: this.state.allEmpCopy,
        employeeSearchField: e.target.value,
        searchLoading: true
      }, () => this.setState({ searchLoading: false }));
    } else if (filteredByEmployees.length > 0) {
      this.setState({
        allEmp: filteredByEmployees,
        employeeSearchField: e.target.value,
        searchLoading: true
      }, () => this.setState({ searchLoading: false }))
    } else {
      this.props.enqueueSnackbar(
        "No SubContractor for the search!",
        {
          variant: "warning",
        }
      );
    }
  }

  filterByRole = (e) => {
    let allEmps = [...this.state.allEmp];

    let filteredByEmployees;

    filteredByEmployees = allEmps.filter(
      (emp) =>
        emp.role_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) || 
        emp.allocated_role.toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    if (e.target.value === "") {
      this.setState({
        allEmp: this.state.allEmpCopy,
        roleSearchField: e.target.value,
        searchLoading: true
      }, () => this.setState({ searchLoading: false }));
    } else if (filteredByEmployees.length > 0) {
      this.setState({
        allEmp: filteredByEmployees,
        roleSearchField: e.target.value,
        searchLoading: true
      }, () => this.setState({ searchLoading: false }))
    } else {
      this.props.enqueueSnackbar(
        "No Roles for the search!",
        {
          variant: "warning",
        }
      );
    }
  }

  updateEmployeeRow = (empRowData, index) => {
    let allempp = [...this.state.allEmp]
    allempp[index] = empRowData
    this.setState({ allEmp: allempp })
  }

  includeLeaves(leavesArray, emp, columns) {
    // debugger
    let allEmployeeSchedules = [...emp.weekdata]
    if (leavesArray.length > 0) {
      for (let leaveindex = 0; leaveindex < leavesArray.length; leaveindex++) {
        for (let colindex = 0; colindex < columns.length; colindex++) {
          let date = moment(columns[colindex]['field'], 'DD/MM/YYYY').toISOString().slice(0, 10)
          if (leavesArray[leaveindex]['date'] === date) {
            allEmployeeSchedules[colindex].leaves = leavesArray[leaveindex]
          }
        }

      }
    }
    return allEmployeeSchedules
  }

  addAllocation(emp, prj) {
    if (this.props.addAllocation) {
      this.props.addAllocation(emp, prj)
    }
  }

  render() {

    const classes = this.props.classes;

    return (
      < div>
        {
          this.state.allEmp.length > 0 ?
            <>
              <div >
                <Table className={classes.table} stickyHeader style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow >
                      <TableCell
                        style={{ zIndex: 99, background: "#F8F8F8", position: "sticky" }}
                        colSpan={2}
                        width={200}
                      >
                        <TextField
                          size='small'
                          onChange={(e) => this.filterByName(e)}
                          id="standard-basic"
                          label="Search SubContractor"
                          type="text"
                          variant="outlined"
                          value={this.state.employeeSearchField}
                          style={{
                            width: '175px',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {this.state.employeeSearchField ? (
                                <IconButton
                                  aria-label="close"
                                  size='small'
                                  onClick={() =>
                                    this.setState({
                                      employeeSearchField: "",
                                      allEmp: this.state.allEmpCopy,
                                    })
                                  }
                                >
                                  <CloseIcon fontSize='small' />
                                </IconButton>
                              ) : null}
                            </InputAdornment>
                          }
                        />
                      </TableCell>
                      <TableCell width={200} align="center" style={{ background: "#F8F8F8", left: '200px', position: 'sticky', zIndex: 999 }}>
                        <TextField
                          size='small'
                          onChange={(e) => this.filterByRole(e)}
                          id="standard-basic"
                          label="Search Role"
                          type="text"
                          variant="outlined"
                          value={this.state.roleSearchField}
                          style={{
                            width: '150px',
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {this.state.roleSearchField ? (
                                <IconButton
                                  aria-label="close"
                                  size='small'
                                  onClick={() =>
                                    this.setState({
                                      roleSearchField: "",
                                      allEmp: this.state.allEmpCopy,
                                    })
                                  }

                                >
                                  <CloseIcon fontSize='small' />
                                </IconButton>
                              ) : null}
                            </InputAdornment>
                          }
                        />
                      </TableCell>
                      {this.state.allMonths.map(month => {
                        return <TableCell key={month.month} colSpan={month.colSpan} align='center' width={month.width}><b>{month.month}</b></TableCell>
                      })}
                    </TableRow>
                    <TableRow >
                      <TableCell colSpan={2} width={200} align="center" style={{ background: "#F8F8F8", zIndex: 999 }}>
                        <b>{"SubContractor"}</b>
                      </TableCell>
                      <TableCell  width={200} align="center" style={{ background: "#F8F8F8", position: "sticky", left: '200px', zIndex: 9999 }}>
                        {"Allocated Roles"}
                      </TableCell>
                      {this.state.columns.map((col, colIndex) => {
                        return <TableCell key={colIndex} align="center" ><b>{col.title}</b></TableCell>
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {this.state.searchLoading ?
                      <LoopCircleLoading /> :
                      this.state.allEmp.map((emp, index) => {
                        let currentEmployeesLeaves = this.props.allEmployeesLeaves.filter(leave => leave.ptd_employee === emp.id)
                        let allEmployeeSchedules = this.includeLeaves(currentEmployeesLeaves, emp, this.state.columns);
                        return <SubContractorRow
                          type={this.props.type}
                          rolesObj={this.props.rolesObj}
                          refreshData={this.handleRefresh}
                          key={index}
                          columns={this.state.columns}
                          liveOpportunities={this.props.liveOpportunities}
                          allEmployeeSchedules={allEmployeeSchedules}
                          allLeaves={currentEmployeesLeaves}
                          emp={emp} index={index}
                          allProjects={this.props.allProjects}
                          allRoles={this.props.allRoles}
                          othertaskObject={this.props.othertaskObject}
                          allEmployeesLeaves={this.props.allEmployeesLeaves}
                          allOtherTasks={this.props.allOtherTasks}
                          selectedStartDate={this.state.selectedStartDate}
                          selectedEndDate={this.state.selectedEndDate}
                          projectObj={this.props.projectObj}
                          role={this.props.role?.id}
                          updateEmployeeRow={(empRowData) => this.updateEmployeeRow(empRowData, index)}
                          addAllocation={this.addAllocation}
                        />
                      })}
                  </TableBody>
                </Table>
              </div>
            </>
            :
            <p style={{ textAlign: "center", padding: '20px' }}>No Employee available for this role.</p>
        }





      </div >)
  }
}

export default withSnackbar(withStyles(useStyles)(DisplaySubontractor))
