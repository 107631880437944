import React, { Component } from 'react'
import { getPrimaryColor } from '../Helper/CommonThings'
import { getCookieNoDecrypt } from '../../utils/CookieHelper'
import moment from 'moment'
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import './ResponsiveTableDesign.css'
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Autocomplete } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { CoffeeLoading } from 'react-loadingg'
import DeleteIcon from '@material-ui/icons/Delete';
import { getClientID } from '../../utils/reuseFunctions'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MaterialTable from 'material-table'


const localizer = momentLocalizer(moment)
// const myEventsList = [
//     {
//         start: moment("2025-01-01T10:00:00").toDate(),
//         title: "Concert",
//         description: "This is a cool event"
//     },
//     {
//         start: moment("2025-01-17T10:00:00").toDate(),
//         title: "Music Workshop",
//         description: "This is a cool event"

//     },
//     {
//         start: moment("2025-01-23T10:00:00").toDate(),
//         title: "Conference",
//         description: "This is a cool event"

//     },
// ];

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};


class ApplyLeaves extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quotes: '',
            data: [],
            openDescriptionDialog: false,
            openAddLeaveDialog: false,
            leaveDate: null,
            categoriesData: [],
            selectedLeave: null,
            employeeData: {},
            selectedStartDate: null,
            selectedCategory: null,
            eventData: [],
            isLoading: true,
            leavesData: [],
            eventObj: {},
            description: '',
            startMonth: moment().format('DD/MM/YYYY'),
            addDisabled: false,
            startDate: null,
            endDate: null,
            multiptleDateCheck: false,
            isTableLoading: false,
            selectedRows: [],
            plannedLeavesData: [],
            submitLeavesDialog: false,
            lineManagerData: {},
            qty: 0.5,
            plannedLeavesDialog: false,
        }
    }

    componentDidMount() {
        // var startDate = moment().startOf('year').format('DD-MM-YYYY');
        // var endDate = moment().endOf('year').format('DD-MM-YYYY');


        var time = moment().format('HH');
        var morningQuotes = ['Rise and shine!', 'Top of the morning to you!', 'Good day to you!', 'Have a great day!', 'Hello there!', 'Morning!', 'Greetings!', 'Good morning sunshine!', 'What a lovely morning!', "Rise n’ shine!", " Very great morning!"]
        var afternoonQuotes = ['Have a wonderful afternoon!', 'Splendid noon!', 'Marvelous afternoon!', 'Have a glorious afternoon!', 'Have a astonishing noon!', 'Golden afternoons!', 'Hope your noon is wonderful!', 'Have a happy afternoon!', 'Bright as the afternoon sun!', 'Fun-filled afternoon!', 'Brightener noon!', 'Wishing you a splendid afternoon!', 'Here’s a wish for a fun afternoon!', 'May your noon be blessed!', 'Brighter noon!']
        var eveningQuotes = ['Have a great evening!', 'Have a wonderful evening!', 'Enjoy your evening!', 'Wishing you a fabulous evening!', 'Have an amazing evening!', 'Enjoy your evening to the fullest!', 'Wishing you the best day ever!']

        if (time < 12) {
            var morning_quotes = morningQuotes[moment().format('ss') % morningQuotes.length];
            this.setState({ quotes: morning_quotes })
        } else if (time < 16) {
            var afternoon_quotes = afternoonQuotes[moment().format('ss') % afternoonQuotes.length];
            this.setState({ quotes: afternoon_quotes })
        } else if (time >= 16) {
            var evening_quotes = eveningQuotes[moment().format('ss') % eveningQuotes.length];
            this.setState({ quotes: evening_quotes })
        }

        this.getEmployeesDetails()

    }

    getEmployeesDetails = () => {
        Service.get(`/api/app/employee/get_employee_details/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            this.setState({ employeeData: res.data, isLoading: false }, () => {
                this.getCategories()
                this.getData()
                this.getLeavesData()
            })
        })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    getData = () => {
        Service.get(`/api/app/employee/get_reporting_instruction_log/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => { this.setState({ data: res.data }) })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    getCategories = () => {
        Service.get(`/api/app/employee/get_categories/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => { this.setState({ leavesData: res.data }) })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    getLeavesData = () => {
        this.setState({
            isTableLoading: true,
        })
        Service.get(`/api/app/employee/employee_leave_in_month/?month_start=${this.state.startMonth}&user=${this.state.employeeData.id}&is_active=True`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            for (let i = 0; i < res.data.length; i++) {
                let element = res.data[i]
                element.start = moment(element.date).toDate()
                element.title = element.category_name + (element.quantity ? ' - ' + element.quantity : '')
            }
            this.setState({ eventData: res.data, isTableLoading: false })
        })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    postLeave = () => {
        this.setState({
            isTableLoading: true,
            addDisabled: true
        })
        if (this.state.selectedCategory === null) {
            this.props.enqueueSnackbar('Please select a category!', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        } else if (this.state.description.length === 0) {
            this.props.enqueueSnackbar('Please enter a description!', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        } else if (this.state.startDate === null) {
            this.props.enqueueSnackbar('Please select a start date!', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        } else if (this.state.endDate === null && this.state.multiptleDateCheck) {
            this.props.enqueueSnackbar('Please select an end date!', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        } else if (this.state.endDate && moment(this.state.startDate).isAfter(this.state.endDate)) {
            this.props.enqueueSnackbar('End date should be greater than start date!', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        } else if (!this.state.qty) {
            this.props.enqueueSnackbar('Please select Qty', { variant: 'warning' })
            this.setState({ isTableLoading: false, addDisabled: false })
            return
        }
        else {
            let data = {
                'category_id': this.state.selectedCategory.id,
                'from_date': moment(this.state.startDate).format('YYYY-MM-DD'),
                'to_date': this.state.multiptleDateCheck ? this.state.endDate ? moment(this.state.endDate).format('YYYY-MM-DD') : moment(this.state.startDate).format('YYYY-MM-DD') : moment(this.state.startDate).format('YYYY-MM-DD'),
                'description': this.state.description
            }
            if (this.state.qty) {
                data.quantity = this.state.qty
            }
            Service.post(`/api/app/employee/create_ptd_emp_leave/`, {
                headers: {
                    Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
                },
                data: data
            }).then(res => {
                this.setState({ qty: 0.5, openAddLeaveDialog: false, selectedCategory: null, description: '', isTableLoading: false, addDisabled: false, startDate: null, endDate: null }, () => {
                    this.getLeavesData()
                })
                this.props.enqueueSnackbar('Leave created!', { variant: 'success' })
            })
                .catch(error => {
                    console.log(error)
                    if (error && error.status === 406) {
                        this.props.enqueueSnackbar(error.data.message, { variant: 'warning' })
                    } else {
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                    }
                    this.setState({ isTableLoading: false, addDisabled: false })
                })
        }
    }

    editDescription = () => {
        this.setState({
            isTableLoading: true
        })
        Service.patch(`/api/app/employee/update_ptd_emp_leave/?id=${this.state.eventObj.id}`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
            data: this.state.eventObj
        }).then(res => {
            this.setState({ openDescriptionDialog: false, eventObj: {}, description: '', isTableLoading: false }, () => {
                this.getLeavesData()
            })
            this.props.enqueueSnackbar('Updated Successfully', { variant: 'success' })
        })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    getLeavesDatabyMonth(date) {
        this.setState({
            startMonth: moment(date).format('DD/MM/YYYY')
        }, () => {
            this.getLeavesData()
        })
    }

    revokeChanges = () => {
        let eventObj = this.state.eventObj
        eventObj.description = eventObj.copyDescription
        this.setState({ eventObj })
    }

    deleteLeave = () => {
        this.setState({
            isTableLoading: true
        })
        Service.delete(`/api/app/employee/delete_ptd_emp_leave/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
            data: {
                id: this.state.eventObj.id
            }
        }).then(res => {
            this.setState({ openDescriptionDialog: false, eventObj: {}, description: '', isTableLoading: false }, () => {
                this.getLeavesData()
            })
            this.props.enqueueSnackbar('Deleted Successfully', { variant: 'success' })
        })
            .catch(error => {
                console.log(error)
                if (error && [400, 401].includes(error.status)) {
                    this.props.enqueueSnackbar(error.data.message, { variant: 'error' })
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                }
                this.setState({ isTableLoading: false })
            })
    }

    handleSelect = ({ event, start, end }) => {
        this.setState({ startDate: moment(start).format('YYYY-MM-DD'), openAddLeaveDialog: true, multiptleDateCheck: false, endDate: null })
    }

    openPlannedLeaves = () => {
        this.setState({ plannedLeavesDialog: true, isTableLoading: true })
        Service.get(`/api/app/employee/get_emp_pending_leaves/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            this.setState({ plannedLeavesData: res.data, isTableLoading: false })
            console.log(res.data)

        })
            .catch(error => {
                if (error.response.status === 406) {
                    this.props.enqueueSnackbar(error.status.message, { variant: 'error' })
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                }
            })
    }

    showLineManager() {
        Service.get(`/api/app/employee/get_emp_line_manager/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },

        }).then(res => {
            this.setState({ lineManagerData: res.data, }, () => {
                this.setState({
                    submitLeavesDialog: true
                })
            })
        })
            .catch(error => {
                console.log(error)
                if (error && error.status === 404) {
                    this.props.enqueueSnackbar('Line manager is not set for you. Contact Admin to sort this out!', { variant: 'error' })

                } else {

                }
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    submitPlannedLeaves() {
        this.setState({
            submitDisabled: true
        })
        let selectedRowID = []
        for (let index = 0; index < this.state.selectedRows.length; index++) {
            const element = this.state.selectedRows[index];
            selectedRowID.push(element.id)
        }
        Service.patch(`/api/app/employee/submit_leave/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
            data: {
                leave_ids: selectedRowID
            }
        }).then(res => {
            this.setState({ isLoading: true, plannedLeavesData: res.data, isTableLoading: false, plannedLeavesDialog: false, submitLeavesDialog: false, submitDisabled: false })
            this.props.enqueueSnackbar('Leaves Submitted Successfully!', { variant: 'success' })
            this.getEmployeesDetails()
        })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    render() {
        return (
            <div>
                {/* <AppBar position="fixed" color="primary">
                    <Toolbar>
                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} >
                            <Box>
                                <Typography align='center'>PT&D Resource Management</Typography>
                            </Box>
                            <Box>
                                <Tooltip title='Planned Leaves' placement='top'>
                                    <IconButton onClick={() => { this.openPlannedLeaves() }}>
                                        <ListAltIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar> */}
                {this.state.isLoading ? <Box display='flex' justifyContent={'center'} flexDirection={'row'} mt={10} alignItems={'center'}><CircularProgress /></Box> :
                    <div style={{ padding: '10px' }}>
                        <Paper style={{ border: "2px solid " + getPrimaryColor(), borderRadius: '10px' }} elevation={3}>
                            <Box p={2} style={{ fontWeight: 'bold' }}>
                                <Typography style={{ fontSize: 'small' }}>Dear {this.state.employeeData.first_name + " " + this.state.employeeData.last_name}</Typography>
                                <Typography style={{ fontSize: 'small' }}>{this.state.quotes}</Typography>
                            </Box>
                        </Paper>

                        <Box mt={2} >
                            <Calendar
                                localizer={localizer}
                                events={this.state.eventData}
                                startAccessor="start"
                                endAccessor="start"
                                style={{ height: 400 }}
                                selectable={true}
                                view='month'
                                views={['month']}
                                components={{
                                    toolbar: (props) => {
                                        return (
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: getPrimaryColor(), color: 'white' }}>
                                                <IconButton color='inherit' onClick={() => { props.onNavigate(navigate.PREVIOUS) }}><KeyboardArrowLeftIcon fontSize='small' /></IconButton>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                    <Typography align='center'>{props.label}</Typography>
                                                    <Tooltip title='Submit Planned Leaves' placement='top'>
                                                        <IconButton onClick={() => { this.openPlannedLeaves() }}>
                                                            <ListAltIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <IconButton color='inherit' onClick={() => { props.onNavigate(navigate.NEXT) }}><KeyboardArrowRightIcon fontSize='small' color='#000' /></IconButton>
                                            </Box>
                                        )
                                    },

                                }}
                                eventPropGetter={event => (event.status === 1) ? (
                                    {
                                        style: {
                                            backgroundColor: '#f09e00',
                                        },
                                    }
                                ) : (event.status === 2) ? (
                                    {
                                        style: {
                                            backgroundColor: '#00aa39',
                                        },
                                    }
                                ) :
                                    (event.status === 3) ? (
                                        {
                                            style: {
                                                backgroundColor: '#808080',
                                            },
                                        }
                                    ) : (event.status === 5) ? (
                                        {
                                            style: {
                                                backgroundColor: '#00802b',
                                            },
                                        }
                                    ) : (null)}
                                onNavigate={(date) => this.getLeavesDatabyMonth(date)}
                                onSelectEvent={event => {
                                    let eventObj = event
                                    eventObj.copyDescription = event.description
                                    console.log(eventObj)
                                    this.setState({ openDescriptionDialog: true, eventObj: eventObj })
                                }}
                                longPressThreshold={10}
                                onSelectSlot={this.handleSelect}
                            />
                        </Box>
                        <Box mt={5} style={{ zoom: 0.8 }}>
                            <table className='table'>
                                <thead>
                                    <tr className='tr'>
                                        {/* <th className='th'>Emp ID</th> */}
                                        {/* <th className='th'>Emp Name</th> */}
                                        {/* <th className='th'>CRM Name</th> */}
                                        <th className='th'>CRM</th>
                                        <th className='th'>Reporting Date</th>
                                        <th className='th'>Site Address</th>
                                        <th className='th'>Travel Date</th>
                                        <th className='th'>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((row) => (
                                        <tr key={row.id} className='tr'>
                                            <td className='td'>
                                                <span className="cell-header">CRM:&nbsp;</span> {row.crm_name}
                                            </td>
                                            <td className='td'>
                                                <span className="cell-header">Reporting Date:&nbsp;</span> {row.reporting_date ? moment(row.reporting_date).format('DD-MM-YYYY') : null}
                                            </td>
                                            {window.innerWidth >= 600 ?
                                                <td className='td'>
                                                    <span className="cell-header">Site Address:&nbsp;</span>{row.site_address}
                                                </td>
                                                :
                                                <div style={{ padding: '8px' }} >
                                                    <div style={{ width: window.innerWidth / 1.2, flexWrap: 'wrap' }}><b>Site Address:&nbsp;</b>{row.site_address}</div>
                                                </div>}
                                            <td className='td'>
                                                <span className="cell-header">Travel Date:&nbsp;</span> {row.travel_date ? moment(row.travel_date).format('DD-MM-YYYY') : 'N/A'}
                                            </td>
                                            {window.innerWidth >= 600 ?
                                                <td className='td'>
                                                    <span className="cell-header">Comments:&nbsp;</span>{row.comments}
                                                </td>
                                                :
                                                <div style={{ padding: '8px' }} >
                                                    <div style={{ width: window.innerWidth / 1.2, flexWrap: 'wrap' }}><b>Comments:&nbsp;</b>{row.comments}</div>
                                                </div>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </div>
                }
                {/* <Zoom in={this.state.zoom}  style={{ transitionDelay: '1500ms' }}>
                    <Fab size="small" color="secondary" aria-label="add" style={{ position: 'fixed', bottom: '10px', right: '10px' }} onClick={() => { this.setState({ openAddLeaveDialog: true }) }}>
                        <AddIcon />
                    </Fab>
                </Zoom> */}
                <Dialog maxWidth="sm" open={this.state.isTableLoading} aria-labelledby="form-dialog-title">
                    <Box m={3} p={3}>
                        <CoffeeLoading color={getPrimaryColor()} size='small' />
                    </Box>
                </Dialog>
                <Dialog open={this.state.openDescriptionDialog} onClose={() => { this.setState({ openDescriptionDialog: false }) }}>
                    <DialogTitle id="alert-dialog-title">
                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                            <Box display={'flex'} flexGrow={1}>{this.state.eventObj.category_name}</Box>
                            {this.state.eventObj.status === 0 ?
                                <Tooltip title='Delete Leave' placement='top'>
                                    <DeleteIcon fontSize='small' onClick={() => { this.deleteLeave() }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                                </Tooltip> : null}
                            <CloseIcon fontSize='small' onClick={() => { this.setState({ openDescriptionDialog: false }) }} style={{ cursor: 'pointer', color: 'red' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display='flex' flexDirection='row' alignItems={'center'}>
                            <Box display='flex' flexDirection='column' alignItems={'center'}>
                                <TextField
                                    value={this.state.eventObj.description}
                                    variant='outlined'
                                    size='small'
                                    multiline
                                    fullWidth
                                    minRows={2}
                                    disabled={this.state.eventObj.status !== 0}
                                    label='Comments'
                                    onChange={(e) => { this.setState({ eventObj: { ...this.state.eventObj, description: e.target.value } }) }}
                                />
                                <FormControl variant="outlined" size='small' style={{ minWidth: '100%', marginTop: '5px' }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Qty</InputLabel>
                                    <Select
                                        native
                                        value={this.state.eventObj.quantity}
                                        onChange={(e) => { this.setState({ eventObj: { ...this.state.eventObj, quantity: e.target.value } }) }}
                                        label="Qty"
                                        fullWidth={true}
                                        disabled={this.state.eventObj.status !== 0}
                                        style={{ marginTop: '10px', width: '100%' }}
                                        inputProps={{
                                            name: 'qty',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option value={0.5}>0.5</option>
                                        <option value={1}>1</option>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <Box marginLeft={'5px'} display='flex' flexDirection='row' alignItems={'center'}>
                                    <IconButton size='small' onClick={() => { this.editDescription() }}>
                                        <DoneIcon fontSize='small' />
                                    </IconButton>
                                    {/* <IconButton size='small' onClick={() => this.revokeChanges()}>
                                        <CloseIcon fontSize='small' />
                                    </IconButton> */}
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={this.state.openAddLeaveDialog}>
                    <DialogTitle id="alert-dialog-title">
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6'>Add Leaves</Typography>
                            <CloseIcon onClick={() => { this.setState({ openAddLeaveDialog: false, selectedLeave: null, startDate: null, endDate: null, selectedCategory: null, description: '' }) }} style={{ cursor: 'pointer', color: 'red' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Box >
                                <Autocomplete
                                    id="role_id"
                                    options={this.state.leavesData}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => this.setState({ selectedCategory: value })}
                                    size='small'
                                    style={{ width: '250px' }}
                                    value={this.state.selectedCategory}
                                    renderInput={(params) => (
                                        <TextField {...params} size='small' variant="outlined" label="Category" placeholder="Category" />
                                    )}
                                />
                            </Box>
                            <Box marginTop={'10px'} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <TextField
                                        type='date'
                                        format="dd/MM/yyyy"
                                        id="from_date"
                                        label="Start Date"
                                        value={this.state.startDate}
                                        onChange={(e) => this.setState({ startDate: e.target.value })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ width: '250px' }}
                                        variant='outlined'
                                        size='small'
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                            {this.state.multiptleDateCheck ?
                                <Box marginTop={'10px'}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <TextField
                                            type='date'
                                            format="dd/MM/yyyy"
                                            id="from_date"
                                            label="End Date"
                                            value={this.state.endDate}
                                            autoFocus={true}
                                            minDate={this.state.startDate}
                                            onChange={(e) => this.setState({ endDate: e.target.value })}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            style={{ width: '250px' }}
                                            inputProps={{ min: this.state.startDate }}
                                            variant='outlined'
                                            size='small'
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box> : null}
                            <Box marginTop={'10px'} display={'flex'} flexDirection={'row'} alignItems={'center'} width={'250px'}>
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <FormControlLabel size='small' control={<Checkbox size='small' checked={this.state.multiptleDateCheck} onChange={(e) => { if (!e.target.checked) { this.setState({ endDate: null }) } this.setState({ multiptleDateCheck: !this.state.multiptleDateCheck }) }}
                                    />} label='Add For Multiple Days' />
                                </Box>
                            </Box>
                            <Box marginTop={'10px'}>
                                <TextField size='small' variant="outlined" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} label="Description" placeholder="Description" style={{ width: '250px' }} />
                            </Box>
                            <Box>
                                <FormControl variant="outlined" size='small' style={{ minWidth: '100%', marginTop: '10px' }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Qty</InputLabel>
                                    <Select
                                        native
                                        value={this.state.qty}
                                        onChange={(e) => this.setState({ qty: e.target.value })}
                                        label="Qty"
                                        fullWidth={true}
                                        style={{ width: '100%' }}
                                        inputProps={{
                                            name: 'qty',
                                            id: 'outlined-age-native-simple',
                                        }}>
                                        <option value={0.5}>0.5</option>
                                        <option value={1}>1</option>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' size='small' disabled={this.state.addDisabled} onClick={() => { this.postLeave() }} color="primary">Add</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.plannedLeavesDialog} >
                    <DialogTitle>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6'>Submit Planned Leaves</Typography>
                            <CloseIcon onClick={() => { this.setState({ plannedLeavesDialog: false, selectedLeave: null, startDate: null, endDate: null, selectedCategory: null, description: '' }) }} style={{ cursor: 'pointer', color: 'red' }} />
                        </Box>
                    </DialogTitle>
                    {/* <DialogContent> */}
                    <MaterialTable
                        isLoading={this.state.isTableLoading}
                        title={''}
                        columns={[
                            { title: 'Category', field: 'other_tasks__name' },
                            { title: 'Qty', field: 'quantity' },
                            { title: 'Date', field: 'date', render: rowData => moment(rowData.date).format('DD-MM-YYYY') },
                        ]}
                        data={this.state.plannedLeavesData}
                        options={{
                            selection: true,
                            rowStyle: {
                                fontSize: 10,
                            },
                            title: false
                        }}
                        onSelectionChange={(rows) => this.setState({ selectedRows: rows })}
                    />
                    {/* </DialogContent> */}
                    <DialogActions>
                        <Button variant='contained' size='small' onClick={() => { this.showLineManager() }} color="primary">Submit</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.submitLeavesDialog} maxWidth='lg' >
                    <DialogTitle>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6'>Confirmation</Typography>
                            <CloseIcon onClick={() => { this.setState({ submitLeavesDialog: false, selectedLeave: null, startDate: null, endDate: null, selectedCategory: null, description: '' }) }} style={{ cursor: 'pointer', color: 'red' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            <div>
                                {this.state.lineManagerData?.first_name || this.state.lineManagerData?.last_name || this.state.lineManagerData?.role ?
                                    'The Approval Request will be sent to ' + this.state.lineManagerData?.first_name + " " + this.state.lineManagerData?.last_name + '(' + this.state.lineManagerData?.role + ') - ' + this.state.lineManagerData?.email :
                                    'There is no Line Manager assigned to you.'}
                            </div>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {this.state.lineManagerData?.first_name || this.state.lineManagerData?.last_name || this.state.lineManagerData?.role ?
                            <Button variant='contained' disabled={this.state.submitDisabled} size='small' onClick={() => { this.submitPlannedLeaves() }} color="primary">Submit</Button> : null}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ApplyLeaves);
