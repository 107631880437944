import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, Slide, Toolbar, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'
import CloseIcon from '@material-ui/icons/Close';
import PRPTemplate from '../../PRPTemplate/PRPTemplate';
import { getSecondaryColor } from '../../Helper/CommonThings';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { getCookie } from '../../../utils/CookieHelper';
import Service from '../../../config/networkutils';
import { withSnackbar } from 'notistack';
import UploadIcon from '@material-ui/icons/CloudUpload'

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"))
var pendingClick = 0;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOppurtunity: {},
            open: false,
            selectedData: null,
        }
        this.PRPTemplateRef = React.createRef()
    }

    openAlert = (data, event) => {
        if (!localStorage.getItem('ClickFunctionalitiesChanged')) {
            this.setState({ alertOpen: true, selectedData: data })
        } else {
            this.setData(data, event)
        }
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    setData(data, event) {
        // kill any pending single clicks
        if (pendingClick) {
            clearTimeout(pendingClick);
            pendingClick = 0;
        }

        switch (event.detail) {
            case 1:
                pendingClick = setTimeout(() => {
                    let obj = {
                        id: data.id,
                        contractor_start_date: data.contractor_start_date,
                        contractor_end_date: data.contractor_end_date,
                        name: data.name,
                        opportunity_id: data.opportunity_id
                    }
                    this.setState({ selectedOppurtunity: obj }, () => { this.setState({ open: true }) })
                }, 500);
                break;
            case 2:
                this.getProjectData(data)
                break;
            default:
                console.log('higher multi-click actions can be added as needed');
                break;
        }
    }

    getProjectData(data) {
        // if (localStorage.getItem('lastSelectedProject')) {
        //     let lastSelectedProject = JSON.parse(localStorage.getItem('lastSelectedProject'))
        //     if (lastSelectedProject.id === data.id) {
        //         return
        //     }
        // }
        if (this.props.getGraph) {
            this.props.getGraph(data)
            // localStorage.setItem('lastSelectedProject', JSON.stringify(data))
        }
    }

    handleClose = () => {
        this.setState({ open: false })
        localStorage.removeItem('searchKey')
        localStorage.removeItem('openedRows')
    }

    postFavourite(rowData) {
        this.setState({ isLoading: true })
        if ('crm_user_pref_id' in rowData) {
            let obj = {
                "is_favourite": !rowData.is_favourite,
            }
            Service.patch(`/api/crm/user/pref/${rowData.crm_user_pref_id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.props.getData()
                    this.setState({ isLoading: false })
                    this.props.disableShowAll()
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    console.log(error)
                    // this.props.enqueueSnackbar('Something went wrong!', {
                    //     variant: 'error'
                    // });
                })
        } else {
            let obj = {
                "crm": rowData.id,
                "ptd_employee": user.id,
                "is_favourite": !rowData.is_favourite,
                "created_by": user.id
            }
            Service.post(`/api/crm/user/pref/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: obj,
            })
                .then(res => {
                    this.props.getData()
                    this.setState({ isLoading: false })
                    this.props.disableShowAll()
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    console.log(error)
                    // this.props.enqueueSnackbar('Something went wrong!', {
                    //     variant: 'error'
                    // });
                })

        }
    }

    handleAlertClose = () => {
        this.setState({ alertOpen: false }, () => {
            var e = new CustomEvent('click', { detail: 1 });
            this.setData(this.state.selectedData, e)
            // this.setData(this.state.selectedData, event)
            localStorage.setItem('ClickFunctionalitiesChanged', true)
        })
    }

    render() {
        const access = this.props.access.access
        return (
            <div>
                <div>
                    <Scrollbar style={{ width: this.screenSizeCheck() ? "350px" : window.innerWidth - (window.innerWidth / 1.6), height: window.innerHeight - 175 }}>
                        <List>
                            {this.props.data.map((item, index) => {
                                return (
                                    <ListItem key={index} button onClick={(event) => this.openAlert(item, event)}>
                                        {item.name}
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => this.postFavourite(item)}>
                                                {item.is_favourite ? <StarIcon style={{ color: getSecondaryColor() }} /> : <StarBorderIcon style={{ color: getSecondaryColor() }} />}
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                            }
                        </List>
                    </Scrollbar>
                </div>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'}>
                                <Box flexGrow={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6">
                                        {this.state.selectedOppurtunity.name}
                                    </Typography>
                                </Box>
                                {access.l6a && access.l6a.l6a_c ?
                                    <Button
                                        variant="oultined"
                                        color="inherit"
                                        onClick={() => { if (this.PRPTemplateRef.current) this.PRPTemplateRef.current.openBulkUpload() }}
                                        size='small'
                                        startIcon={<UploadIcon />}>
                                        Upload PRP Data Excel
                                    </Button>
                                    : null}
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box margin={2} marginTop={9}>
                        <PRPTemplate
                            ref={this.PRPTemplateRef}
                            project={this.state.selectedOppurtunity}
                            // closeModal={this.handleClose} 
                            addPRPAccess={access.l6a && access.l6a.l6a_b}
                            editPRPAccess={access.l6a && access.l6a.l6a_c}
                            uploadPRPAccess={access.l6a && access.l6a.l6a_c}
                            deletePRPAccess={access.l6a} />
                    </Box>
                </Dialog>

                <Dialog fullWidth open={this.state.alertOpen} TransitionComponent={Transition}>
                    <DialogTitle id="alert-dialog-slide-title" className='blink'>{"Project Click Functionalities!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Single Click & Double Click Functionalities differ as below<br />
                            <b>Single Click</b> - View Project Details<br />
                            <b>Double Click</b> - View Project Demand vs Capacity & Attrition Graph
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button size='small' onClick={this.handleAlertClose} color="primary">
                            Continue & Close
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(ProjectList)
