import React, { Component } from 'react';
import { getCookie } from '../../../utils/CookieHelper';
import { withSnackbar } from 'notistack';
import Service from '../../../config/networkutils';
import MaterialTable from 'material-table';

const user = JSON.parse(getCookie("ptd_ts_user"))
const token = getCookie("ptd_ts_token")

class LeaveRestriction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            categoriesLookup: {},
        }
    }

    componentDidMount() {
        this.fetchCategory()
    }

    fetchCategory() {
        Service.get('/api/other/tasks/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let categoriesLookup = {}
                res.data.forEach(element => {
                    if (element.is_active) {
                        categoriesLookup[element.id] = element.name
                    }
                });
                this.setState({ categoriesLookup: categoriesLookup }, () => {
                    this.fetchData()
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    fetchData() {
        this.setState({ isLoading: true })
        Service.get(`/api/other/task/limit/?year=${this.props.year}&ptd_employee=${this.props.employeeData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data, isLoading: false })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    postOps(data) {
        let addedCategory = this.state.data.find(element => element.other_tasks === parseInt(data.other_tasks))
        if (addedCategory) {
            this.props.enqueueSnackbar('Category already exists!', {
                variant: 'warning'
            });
            this.fetchData()
        } else {
            var fields = ''
            if (!data.other_tasks) {
                fields = "Category"
            }
            if (!data.leave_quantity) {
                fields += !fields ? "Quantity" : ", Quantity"
            }

            if (!fields) {
                data.created_by = user.id
                data.ptd_employee = this.props.employeeData.id
                data.updated_by = user.id
                data.year = this.props.year
                Service.post(`/api/other/task/limit/`, {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: data
                })
                    .then(res => {
                        this.fetchData()
                        this.props.enqueueSnackbar('Category added successfully!', {
                            variant: 'success'
                        });
                    })
                    .catch(error => {
                        console.log(error)
                        this.props.enqueueSnackbar('Something went wrong!', {
                            variant: 'error'
                        });
                        this.fetchData()
                    });
            } else {
                this.props.enqueueSnackbar(fields + ' is Empty!', {
                    variant: 'warning'
                });
                this.fetchData()
            }
        }
    }

    putOps(data) {
        // let addedCategory = this.state.data.find(element => element.other_task === data.other_task)
        // if (addedCategory) {
        //     this.props.enqueueSnackbar('Category already exists!', {
        //         variant: 'warning'
        //     });
        //     this.fetchData()
        // } else {
        var fields = ''
        if (!data.other_tasks) {
            fields = "Category"
        }
        if (!data.leave_quantity) {
            fields += !fields ? "Quantity" : ", Quantity"
        }

        if (!fields) {
            data.updated_by = user.id
            Service.patch(`/api/other/task/limit/${data.id}/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.fetchData()
                    this.props.enqueueSnackbar('Category Updated successfully!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.fetchData()
                });
        } else {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
            this.fetchData()
        }
        // }
    }

    deleteOps(data) {
        Service.delete(`/api/other/task/limit/${data.id}/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.fetchData()
                this.props.enqueueSnackbar('Category Deleted successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchData()
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    key={3}
                    title="Leaves Restriction"
                    columns={[
                        {
                            title: 'Category', field: 'other_tasks',
                            lookup: this.state.categoriesLookup,
                        }, {
                            title: 'Quantity', field: 'leave_quantity', type: 'numeric',
                        },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        padding: "dense",
                        addRowPosition: 'first',
                    }}
                    editable={{
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        this.postOps(newData);
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            this.putOps(newData)
                                            return { ...prevState, data };
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteOps(oldData)
                                        return { ...prevState, data };
                                    });
                                }, 600);
                            }),
                    }}
                />
            </div>
        );
    }
}

export default withSnackbar(LeaveRestriction);