import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookieNoDecrypt } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import MaterialTable from 'material-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import moment from 'moment';
import { getClientID } from '../../utils/reuseFunctions';

class LeaveStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoading: true,
            userAccess: {},
            open: false,
            selectedRows: [],
            disabled: true,
            alertDialog: false,
        }
    }

    getData() {
        Service.get(`/api/app/employee/get_leave_approval_status/?id=${this.props.data.id}`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            this.setState({ data: res.data, isLoading: false })
        })
            .catch(error => {
                console.log(error)
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    componentDidMount() {
        this.getData()
    }

    approveLeave = (approvalStatus) => {
        this.setState({ disabled: true })
        let id = []
        for (let index = 0; index < this.state.selectedRows.length; index++) {
            const element = this.state.selectedRows[index];
            id.push(element.id)
        }
        this.setState({ isLoading: true })
        if (this.state.alertDialog) {
            this.setState({ alertDialog: false })
        }
        Service.patch(`/api/app/employee/lm_approve_or_reject_leave/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
            data: {
                approval_id: this.state.data.id,
                leave_id: id,
                approval_status: approvalStatus,
            }
        }).then(res => {
            this.getData()
            if (approvalStatus === 2) {
                this.props.enqueueSnackbar('Leaves Rejected ', { variant: 'success' })
            } else {
                this.props.enqueueSnackbar('Leaves Approved ', { variant: 'success' })
            }
            this.props.reload()
            this.setState({ disabled: false })
        })
            .catch(error => {
                console.log(error)
                this.setState({ disabled: false })
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    getStatus = (status) => {
        switch (status) {
            case 0:
                return 'Pending'
            case 1:
                return 'Approved'
            case 2:
                return 'Rejected'
            case 3:
                return 'Partially Approved'
            default:
                return ''
        }
    }

    rejectCheck = () => {
        let data = this.state.data.leave_history
        let selectedRows = this.state.selectedRows
        if (selectedRows.length < data.length) {
            this.setState({ alertDialog: true })
        } else {
            this.approveLeave(2)
        }
    }

    render() {
        return (
            <div>
                <Box marginTop={10} margin={2}>
                    <Box display='flex' justifyContent={'center'} alignItems={'center'} marginBottom={2}>
                        {this.state.data.approval_status === 0 ?
                            <div>
                                <Button size='small' color='primary' disabled={this.state.disabled} variant='contained' onClick={() => this.approveLeave(1)}>
                                    Approve
                                </Button>
                                <Button style={{ marginLeft: '5px' }} size='small' color='primary' disabled={this.state.disabled} variant='contained' onClick={() => this.rejectCheck()}>
                                    Reject
                                </Button>
                            </div> :

                            ([1, 2, 3].includes(this.state.data?.approval_status) ?
                                <Box>{`This request has been ${this.getStatus(this.state.data?.approval_status)} by ${this.state.data?.approved_by_first_name + ' ' + this.state.data?.approved_by_last_name} on ${moment(this.state.data?.approved_on).format('DD-MM-YYYY')}`} </Box> : null)
                        }
                    </Box>
                    <MaterialTable
                        columns={[
                            {
                                title: 'Category', field: 'category_name',
                            }, {
                                title: 'Date', field: 'date',
                                render: rowData => moment(rowData.date).format('DD-MM-YYYY')
                            }, {
                                title: 'Comments', field: 'comments',
                            }, {
                                title: 'Qty', field: 'quantity',
                            }, {
                                title: 'Leave Approval Status', field: 'leave_approval_status',
                                lookup: { 0: 'Planned', 1: 'Pending', 2: 'Approved', 3: 'Rejected', 4: 'Deleted', 5: 'Admin Approved' }
                            }
                        ]}
                        data={this.state.data.leave_history}
                        isLoading={this.state.isLoading}
                        title="Leave Approval"
                        options={{
                            paging: false,
                            selection: this.state.data.approval_status === 0 ? true : false,
                        }}
                        onSelectionChange={(rows) => {
                            this.setState({ selectedRows: rows })
                            if (rows.length > 0) {
                                this.setState({ disabled: false })
                            } else {
                                this.setState({ disabled: true })
                            }
                        }}
                    />
                </Box>

                <Dialog fullWidth maxWidth='xs' open={this.state.alertDialog} onClose={() => this.setState({ promotionHistorySchedules: false })}>
                    <DialogTitle><Typography variant='h5' >Note</Typography></DialogTitle>
                    <DialogContent>
                        <Typography variant='body1' >Unchecked items will be rejected on Approval.Do you want to proceed?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button size={'small'} onClick={() => this.setState({ alertDialog: false })} color="primary">
                            Cancel
                        </Button>
                        <Button size='small' onClick={() => this.approveLeave(2)} color="primary">
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withSnackbar(LeaveStatus);