import MaterialTable from 'material-table'
import React, { Component } from 'react'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';

const token = getCookie("ptd_ts_token");
const user = JSON.parse(getCookie("ptd_ts_user"));

class SubContractorComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            data: []
        }
    }

    componentDidMount() {
        this.getSubContractor()
    }

    getSubContractor = () => {
        Service.get('/api/subcontractor/company/get_with_delete_status/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data }, () => {
                    this.setState({ isLoading: false })
                })

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ buttonDisabled: false })
            });
    }

    createSubContractor = (data) => {
        this.setState({ isLoading: true })
        data.created_by = user.id
        Service.post('/api/subcontractor/company/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({ data: res.data.roles }, () => {
                    this.getSubContractor()
                })
                this.props.enqueueSnackbar('Added successfully', {
                    variant: 'success'
                });

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ buttonDisabled: false })
            });
    }

    updateSubContractor = (data) => {
        this.setState({ isLoading: true })
        Service.put('/api/subcontractor/company/' + data.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.setState({ data: res.data.roles }, () => {
                    this.getSubContractor()
                })
                this.props.enqueueSnackbar('Updated successfully', {
                    variant: 'success'
                });

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ buttonDisabled: false })
            });
    }

    deleteSubContractor = (data) => {
        this.setState({ isLoading: true })
        Service.delete('/api/subcontractor/company/' + data.id + "/", {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                this.getSubContractor()
                this.props.enqueueSnackbar('Deleted successfully', {
                    variant: 'success'
                });
                this.setState({ isLoading: false })
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.fetchOps();
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={"SubContractor"}
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Address', field: 'address' },
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                    }}
                    actions={[
                        rowData => ({
                            icon: 'delete',
                            tooltip: !rowData.is_deletable ? "You can't delete this item because it is linked to the schedules" : "Delete",
                            disabled: !rowData.is_deletable,
                            onClick: (event, rowData) => this.deleteSubContractor(rowData),
                        }),
                    ]}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.createSubContractor(newData)
                                    resolve();
                                }, 1000)
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    this.updateSubContractor(newData)
                                    resolve();
                                }, 1000)
                            }),
                    }}
                /></div>
        )
    }
}

export default withSnackbar(SubContractorComponent)
