import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Persons from '@material-ui/icons/SupervisorAccount';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';

// import CRMTable from '../CRM/CRMTable';
import CRMInput from '../LinkedTables/LinkedTables';
// import Roles from '../Roles/Roles';
// import PRPTemplate from '../PRPTemplate/PRPTemplate';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
// import UnsecuredProjects from '../UnsecuredProjects/UnsecuredProjects';
import Loader from '../../shared/Loader/Loader';
import Users from '../Users/Users';
// import SecuredProject from '../Secured/Secured';
import { Avatar, Box, Tooltip, CardMedia, Button } from '@material-ui/core';
import Auth from '../../utils/authenticate';
import { withRouter } from 'react-router-dom';
import { PersonAdd, Settings, } from '@material-ui/icons';
import SecurityIcon from '@material-ui/icons/Security';
import UserAccess from '../UserAccess/UserAccess';
// import Reports from '../Reports/Reports';
import Service from "../../config/networkutils";
import { withSnackbar } from 'notistack';
import ManageHR from '../UserAccess/ManageHR';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
// import PRPLiveChange from '../PRPLiveChange/PRPLiveChange';
import RoleTabs from '../Roles/RoleTabs';
import { BlockLoading } from 'react-loadingg';
import UIRefereshment from '../UIRefereshment/UIRefereshment';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SubContractorComponent from '../SubContractor/SubContractorComponent';
// import LeaveApproval from '../Leaves/LeaveApproval';
// import DvsCReports from '../DvsCReports.js/DvsCReports';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import LeaveAdminAccess from '../Leaves/LeaveAdminAccess';
// import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

const drawerWidth = 190;

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        width: `calc(100% - ${drawerWidth}px)`,
    },
    profileButton: {
        marginLeft: "auto",
        cursor: "pointer"
    },
    primaryColorBg: {
        color: '#005D99'
    },
    orange: {
        color: '#005D99 ',
        backgroundColor: '#fff',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    userAvatar: {
        height: '50px',
        width: '50px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px'
    },
    infoBox: {
        marginBottom: '15px'
    },
    profileContainer: {
        textAlign: 'center'
    }
});
var pjson = require('../../../package.json');
// const sideMenu = ['CRM', 'CRM Inputs', "Roles", "Employees", "Unsecured", "Secured", "Reports", "Users & Access"]
class Home extends React.Component {

    constructor() {
        super()
        this.state = {
            sideMenu: [
                // 'CRM', 'CRM Inputs', "Roles", "Employees", "Unsecured", "Secured", "Reports", "Users & Access"
            ],
            menu_open: false,
            current_comp: '',
            isLoading: false,
            anchorEl: null,
            userAccess: {},
            userData: { first_name: '', last_name: '' },
            isInitLoading: true
        }

    }

    componentDidMount() {
        // window.addEventListener('popstate', (event) => {
        //     sessionStorage.setItem('lasturi', window.location.href)
        // });

        // if (sessionStorage.getItem('lasturi') === null) {
        //     sessionStorage.setItem('lasturi', window.location.href)
        // }

        // if (sessionStorage.getItem('lasturi')) {
        //     window.history.pushState({ id: window.location.href }, '', sessionStorage.getItem('lasturi'))
        // }
        this.fetchUserAccess()
        this.setState({ userData: Auth.user() })
    }

    fetchUserAccess() {
        const user = Auth.user()
        const token = Auth.token()

        Service.get(`/api/access/?uid=` + user.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                localStorage.setItem('ptd_ts_access', JSON.stringify(res.data.length ? res.data[0] : {}))
                this.setState({
                    userAccess: res.data.length ? res.data[0] : {}
                }, () => {
                    this.compileMenu(user)
                });

            })
            .catch(error => {
                // this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Uh oh! Something went wrong. Please try reloading the page or try later.', {
                    variant: 'error',
                    persist: true
                });
            });
    }


    compileMenu(user) {
        const access = { ...this.state.userAccess.access }
        let menus = []
        var defaultView = '';
        var hashDone = false;
        let viewHash;
        // if (sessionStorage.getItem("lasturi") === null) {
        viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ');
        if (access.l0a && access.l0a.l0a_a) {
            if (viewHash === 'Dashboard') {
                hashDone = true;
                this.setState({
                    current_comp: viewHash,
                })
            }
            menus.push('Dashboard')
            if (defaultView === '') {
                defaultView = 'Dashboard'
            }
            // if (access.l1a && access.l1a.l1a_a) {
            //     menus.push('CRM')
            // }
            if (access.l2a && access.l2a.l2a_a) {
                if (viewHash === 'CRM Inputs') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('CRM Inputs')
                if (defaultView === '') {
                    defaultView = 'CRM Inputs'
                }
            }
            if (access.l3a && access.l3a.l3a_a) {
                if (viewHash === 'Roles') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('Roles')
                if (defaultView === '') {
                    defaultView = 'Roles'
                }
            }
            if (access.l4a && access.l4a.l4a_a) {
                if (viewHash === 'Employees') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('Employees')
                if (defaultView === '') {
                    defaultView = 'Employees'
                }
            }

            if (access.l12a && access.l12a.l12a_a) {
                if (viewHash === 'SubContractor') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('SubContractor')
            }

            // if (access.l11a && access.l11a.l11a_a) {
            //     if (viewHash === 'Admin Level Approval') {
            //         hashDone = true;
            //         this.setState({
            //             current_comp: viewHash,
            //         })
            //     }
            //     menus.push('Admin Level Approval')
            //     if (defaultView === '') {
            //         defaultView = 'Admin Level Approval'
            //     }
            // }

            // if (access.l11a && access.l11a.l11a_b) {
            //     if (viewHash === 'User Level Approval') {
            //         hashDone = true;
            //         this.setState({
            //             current_comp: viewHash,
            //         })
            //     }
            //     menus.push('User Level Approval')
            //     if (defaultView === '') {
            //         defaultView = 'User Level Approval'
            //     }
            // }

            // if (access.l5a && access.l5a.l5a_a) {
            //     menus.push('Unsecured')
            // }
            // if (access.l6a && access.l6a.l6a_a) {
            //     menus.push('Secured')
            // }
            // if (access.l7a && access.l7a.l7a_a) {
            //     menus.push('Reports')
            // }
            // if (access.l9a && access.l9a.l9a_a) {
            //     menus.push('Combined Resources')
            // }
            // 
            // if (access.l7a && access.l7a.l7a_a) {
            //     menus.push('Reports')
            //     if (defaultView === '') {
            //         defaultView = 'Reports'
            //     }
            // }

            if (user.is_hr === true) {
                if (viewHash === 'Manage Users') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('Manage Users')
                if (defaultView === '') {
                    defaultView = 'Manage Users'
                }
            }
            if (user.is_admin === true) {
                if (viewHash === 'Manage HR') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('Manage HR')
                if (defaultView === '') {
                    defaultView = 'Manage HR'
                }
            }

            if (access.l11a && access.l11a.l11a_a) {
                if (viewHash === 'Leaves Admin') {
                    hashDone = true;
                    this.setState({
                        current_comp: viewHash,
                    })
                }
                menus.push('Leaves Admin')
                if (defaultView === '') {
                    defaultView = 'Leaves Admin'
                }
            }

            // if (viewHash === 'UIRefreshment') {

            // // if (viewHash === 'Reports') {
            //     hashDone = true;
            //     this.setState({
            //         current_comp: viewHash,
            //     })
            // }
            // menus.push('UIRefreshment')
            // if (defaultView === '') {
            //     defaultView = 'UIRefreshment'
            // }



            // if (viewHash === 'Graph Component') {
            //     hashDone = true;
            //     this.setState({
            //         current_comp: viewHash,
            //     })
            // }
            // menus.push('Graph Component')
            // if (defaultView === '') {
            //     defaultView = 'Graph Component'
            // }
        }
        if (hashDone) {
            this.setState({
                sideMenu: menus,
                isInitLoading: false
            })
        } else {
            this.setState({
                sideMenu: menus,
                current_comp: "Dashboard",
                isInitLoading: false
            })
        }
        // } else if (sessionStorage.getItem("lasturi").includes('?')) {
        //     if (access.l0a && access.l0a.l0a_a) {

        //         menus.push('Dashboard');

        //         if (access.l2a && access.l2a.l2a_a) {

        //             menus.push('CRM Inputs');

        //         }
        //         if (access.l3a && access.l3a.l3a_a) {

        //             menus.push('Roles');

        //         }
        //         if (access.l4a && access.l4a.l4a_a) {

        //             menus.push('Employees');

        //         }
        //         // if (access.l5a && access.l5a.l5a_a) {
        //         //     menus.push('Unsecured')
        //         // }
        //         // if (access.l6a && access.l6a.l6a_a) {
        //         //     menus.push('Secured')
        //         // }
        //         // if (access.l7a && access.l7a.l7a_a) {
        //         //     menus.push('Reports')
        //         // }
        //         // if (access.l9a && access.l9a.l9a_a) {
        //         //     menus.push('Combined Resources')
        //         // }
        //         if (user.is_hr === true) {

        //             menus.push('Manage Users');

        //         }
        //         if (user.is_admin === true) {

        //             menus.push('Manage HR');

        //         }
        //     }
        //     this.setState({
        //         sideMenu: menus,
        //         isInitLoading: false,
        //         current_comp: "Dashboard",
        //     }, () => {
        //         window.history.pushState({ id: window.location.href }, '', sessionStorage.getItem('lasturi'))
        //     })

        // } else {
        //     viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ');
        //     if (access.l0a && access.l0a.l0a_a) {
        //         if (viewHash === 'Dashboard') {
        //             hashDone = true;
        //             this.setState({
        //                 current_comp: viewHash,
        //             })
        //         }
        //         menus.push('Dashboard')
        //         if (defaultView === '') {
        //             defaultView = 'Dashboard'
        //         }
        //         // if (access.l1a && access.l1a.l1a_a) {
        //         //     menus.push('CRM')
        //         // }
        //         if (access.l2a && access.l2a.l2a_a) {
        //             if (viewHash === 'CRM Inputs') {
        //                 hashDone = true;
        //                 this.setState({
        //                     current_comp: viewHash,
        //                 })
        //             }
        //             menus.push('CRM Inputs')
        //             if (defaultView === '') {
        //                 defaultView = 'CRM Inputs'
        //             }
        //         }
        //         if (access.l3a && access.l3a.l3a_a) {
        //             if (viewHash === 'Roles') {
        //                 hashDone = true;
        //                 this.setState({
        //                     current_comp: viewHash,
        //                 })
        //             }
        //             menus.push('Roles')
        //             if (defaultView === '') {
        //                 defaultView = 'Roles'
        //             }
        //         }
        //         if (access.l4a && access.l4a.l4a_a) {
        //             if (viewHash === 'Employees') {
        //                 hashDone = true;
        //                 this.setState({
        //                     current_comp: viewHash,
        //                 })
        //             }
        //             menus.push('Employees')
        //             if (defaultView === '') {
        //                 defaultView = 'Employees'
        //             }
        //         }
        //         // if (access.l5a && access.l5a.l5a_a) {
        //         //     menus.push('Unsecured')
        //         // }
        //         // if (access.l6a && access.l6a.l6a_a) {
        //         //     menus.push('Secured')
        //         // }
        //         // if (access.l7a && access.l7a.l7a_a) {
        //         if (viewHash === 'OtherTasks') {
        //             hashDone = true;
        //             this.setState({
        //                 current_comp: viewHash,
        //             })
        //         }
        //         menus.push('OtherTasks')
        //         if (defaultView === '') {
        //             defaultView = 'OtherTasks'
        //         }
        //         // }
        //         // if (access.l9a && access.l9a.l9a_a) {
        //         //     menus.push('Combined Resources')
        //         // }
        //         if (user.is_hr === true) {
        //             if (viewHash === 'Manage Users') {
        //                 hashDone = true;
        //                 this.setState({
        //                     current_comp: viewHash,
        //                 })
        //             }
        //             menus.push('Manage Users')
        //             if (defaultView === '') {
        //                 defaultView = 'Manage Users'
        //             }
        //         }
        //         if (user.is_admin === true) {
        //             if (viewHash === 'Manage HR') {
        //                 hashDone = true;
        //                 this.setState({
        //                     current_comp: viewHash,
        //                 })
        //             }
        //             menus.push('Manage HR')
        //             if (defaultView === '') {
        //                 defaultView = 'Manage HR'
        //             }
        //         }
        //     }
        //     if (hashDone) {
        //         this.setState({
        //             sideMenu: menus,
        //             isInitLoading: false
        //         })
        //     } else {
        //         this.setState({
        //             sideMenu: menus,
        //             current_comp: "Dashboard",
        //             isInitLoading: false
        //         })
        //     }
        // }
    }

    removeFromMenu(value) {
        let array = [...this.state.sideMenu];
        const index = array.indexOf(value);
        if (index > -1) {
            array.splice(index, 1);
        }
        this.setState({ sideMenu: array })
    }

    handleDrawerOpen = () => {
        this.setState({ menu_open: true })
    };

    handleDrawerClose = () => {
        this.setState({ menu_open: false })
    };

    changeComp = (compName) => {
        // sessionStorage.setItem('lasturi', `${window.location.protocol}//${window.location.host}/#${compName}`)
        window.location.hash = compName;
        let hash = window.location.hash
        window.history.pushState(null, '', window.location.pathname + hash);
        this.setState({ current_comp: compName })
    }

    setLoading = (flag) => {
        this.setState({ isLoading: flag })
    }

    handleAvatarClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    // handleMenuClick = (e) => {
    //     if (e.target.id === 'profile') {
    //         this.setState({ viewClass: 'Profile', anchorEl: null });
    //     } else if (e.target.id === 'change_passsword') {
    //         this.setState({ viewClass: 'Change Password', anchorEl: null });
    //     } else if (e.target.id === 'signout') {
    //         Auth.removeToken(() => {
    //             this.props.history.push('/')
    //         })
    //     }
    // };
    handleMenuClick = () => {
        Auth.removeToken(() => {
            this.props.history.push('/')
        })
    }

    render() {
        const { classes } = this.props
        return (<>
            {this.state.isInitLoading ? <BlockLoading color='#005D99' /> :
                <div className={classes.root} >
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: this.state.menu_open,
                        })}
                    >
                        <Toolbar >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: this.state.menu_open,
                                })}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap>
                                PT&D Resource Management
                            </Typography>
                            <div className={classes.profileButton}>
                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                        <div>
                                            <Avatar style={{ marginRight: '5px' }} {...bindTrigger(popupState)} className={classes.orange}>
                                                <Box fontSize={12}>
                                                    <Typography>
                                                        {(this.state.userData.first_name ? this.state.userData.first_name.charAt(0) : '') + (this.state.userData.last_name ? this.state.userData.last_name.charAt(0) : '')}
                                                    </Typography>
                                                </Box>
                                            </Avatar>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Box p={2} className={classes.profileContainer}>

                                                    <CardMedia
                                                        component="img"
                                                        alt="Balfour Beatty"
                                                        image={'user.png'}
                                                        title="Balfour Beatty"
                                                        className={classes.userAvatar}
                                                    />

                                                    <Box className={classes.infoBox}>
                                                        <Typography color="inherit" variant="h5">{this.state.userData.first_name + ' ' + this.state.userData.last_name}</Typography>
                                                        <Typography variant="h6">
                                                            <small>{this.state.userData.email}</small>
                                                        </Typography>
                                                    </Box>

                                                    {/* <MenuItem id="signout" onClick={this.handleMenuClick}>Sign Out</MenuItem> */}
                                                    <Button variant="outlined" onClick={this.handleMenuClick}>Sign Out</Button>
                                                </Box>
                                            </Popover>
                                        </div>
                                    )}
                                </PopupState>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: this.state.menu_open,
                            [classes.drawerClose]: !this.state.menu_open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.menu_open,
                                [classes.drawerClose]: !this.state.menu_open,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <CardMedia
                                component="img"
                                alt="Balfour Beatty"
                                image={'bb.png'}
                                title="Balfour Beatty"
                                style={{ width: '130px' }}
                            />
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List className={classes.primaryColorBg}>
                            {this.state.sideMenu.map((text, index) => (
                                <Tooltip title={!this.state.menu_open ? text : ''} placement="bottom" key={text}>
                                    <ListItem
                                        button
                                        key={text}
                                        onClick={() => { this.changeComp(text) }}
                                        selected={this.state.current_comp === text}
                                    >
                                        <ListItemIcon className={classes.primaryColorBg}>
                                            {
                                                text === 'Dashboard' ? <DashboardIcon /> :
                                                    text === 'CRM Inputs' ? <PlaylistAdd /> :
                                                        text === 'Roles' ? <Persons /> :
                                                            text === 'Employees' ? <PersonAdd /> :
                                                                text === 'Leaves Admin' ? <InsertInvitationIcon /> :
                                                                    text === 'Manage Users' ? <SecurityIcon /> :
                                                                        text === 'Manage HR' ? <Settings /> :
                                                                            text === 'SubContractor' ? <SupervisedUserCircleIcon /> :
                                                                                null
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                    </ListItem>
                                </Tooltip>
                            ))}
                        </List>
                        <Divider />
                    </Drawer>
                    <div style={{
                        position: 'absolute',
                        top: '0px',
                        right: '5px',
                        padding: '0px',
                        marginTop: '68px'
                    }}>
                        <Box fontSize={10} fontWeight="fontWeightLight" >
                            {"v" + pjson.version}
                        </Box>
                    </div>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />

                        {this.state.current_comp === 'Dashboard' ? <UIRefereshment access={this.state.userAccess} /> : null}
                        {this.state.current_comp === 'CRM Inputs' ? <CRMInput isLoading={this.setLoading} access={this.state.userAccess} /> : null}
                        {this.state.current_comp === 'Roles' ? <RoleTabs isLoading={this.setLoading} access={this.state.userAccess} /> : null}
                        {this.state.current_comp === 'Employees' ? <Users isLoading={this.setLoading} access={this.state.userAccess} link={true} /> : null}
                        {this.state.current_comp === 'Leaves Admin' ? <LeaveAdminAccess isLoading={this.setLoading} access={this.state.userAccess} /> : null}
                        {this.state.current_comp === 'Manage Users' ? <UserAccess isLoading={this.setLoading} access={this.state.userAccess} /> : null}
                        {this.state.current_comp === 'Manage HR' ? <ManageHR /> : null}
                        {this.state.current_comp === 'SubContractor' ? <SubContractorComponent /> : null}
                        {/* {this.state.current_comp === 'UIRefreshment' ? <UIRefereshment access={this.state.userAccess} /> : null} */}
                        {/* {this.state.current_comp === 'Combined Resources' ? <PRPLiveChange isLoading={this.setLoading} access={this.state.userAccess} /> : null} */}
                    </main>
                    {this.state.isLoading ? <Loader /> : null}
                </div>
            }

        </>)
    }
}

export default withSnackbar(withRouter(withStyles(useStyles)(Home)))