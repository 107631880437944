import React from 'react'
import Service from '../../config/networkutils';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withSnackbar } from 'notistack';
import { RotateCircleLoading } from 'react-loadingg';
import { getCookie } from '../../utils/CookieHelper';
import moment from 'moment';
import { Button, Grid, Dialog, TextField, IconButton } from '@material-ui/core';
import ResourceTable from './ResourceTable';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getActiveRoles, getNotTrashedCrmWithNoFilter, getOwnerDelivery, getRegion, getSalesStage, getSubSector } from '../../config/PromisesHandler';
import { roles_sectors_regions } from '../../config/apiList';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const token = getCookie("ptd_ts_token");
// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]


const useStyles = (theme) => ({
    appBar: {
        position: 'fixed',
        top: 0,
    },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    date: {
        margin: 0,
        width: '100%',
        'margin-top': '5px'
    },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class PRPLiveChange extends React.Component {
    constructor() {
        super()
        this.state = {
            modelOpen: false,
            selectedOpportunity: [],
            selectedFilter: [],
            opportunity: [],
            loading: false,
            salesStage: [],
            selectedSalesStage: [{ id: 4, name: 'Closed/Won' }],
            roles: [],
            selectedRoles: [],
            columns: [],
            data: [],
            showDialog: false,
            customQtyValues: [],
            selectedEmployee: {},
            region: [],
            subSector: [],
            ownerDelivery: [],
            selectedSubSector: [],
            tableDialog: false,
            timeDuration: [
                { duration: 1, name: '1 month' },
                { duration: 3, name: '3 months' },
                { duration: 6, name: '6 months' },
                { duration: 12, name: '12 months' },
            ],
            selectedTimeDuration: { duration: 12, name: '12 months' },
            selectedRegion: [],
            selectedOwnerDelivery: []
        }
        this.handleReload = this.handleReload.bind(this)
    }

    componentDidMount() {
        this.setState({ loading: true })

        Promise.all([
            getSalesStage(),
            getNotTrashedCrmWithNoFilter(),
            getActiveRoles(),
            getRegion(),
            getSubSector(),
            getOwnerDelivery()
        ])
            .then(res => {
                // let filtered = res[1].filter(ele => (ele.bid_resource && ele.archive_reason < 1))
                // let filtered = res[1].filter(ele => (ele.archive_reason < 1))

                let liveOpportunities = res[1].filter(ele => (ele.archive_reason === 0))

                this.allOpportunity = liveOpportunities;

                this.setState({
                    salesStage: res[0],
                    roles: res[2],
                    region: res[3],
                    subSector: res[4],
                    ownerDelivery: res[5],
                    opportunity: liveOpportunities,
                }, () => {

                    this.filterChange()
                    let searchParams = new URLSearchParams(window.location.search)

                    let selectedRegions = []
                    let selectedSubSectors = []
                    let selectedSalesStages = []
                    let selectedRoles = []
                    let selectedOwnerDeliveries = []
                    let selectedOpportunities = []
                    let selectedTimeDuration = { duration: 12, name: '12 months' }

                    if (searchParams.get("selectedRegion")) {
                        let idNum = searchParams.get("selectedRegion").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perRegion = this.state.region.filter((reg) => reg.id === parseInt(idNum[id]))
                            selectedRegions.push(perRegion[0])
                        }
                        // let parRole = this.state.allRoles.filter((rol) => rol.id === idNum)
                        // if (parRole.length > 0) { this.setState({ selectedRole: parRole[0] }, () => this.fetchOps()) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedSubSector")) {
                        let idNum = searchParams.get("selectedSubSector").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perSS = this.state.subSector.filter((ss) => ss.id === parseInt(idNum[id]))
                            selectedSubSectors.push(perSS[0])
                        }
                        // let parRole = this.state.allRoles.filter((rol) => rol.id === idNum)
                        // if (parRole.length > 0) { this.setState({ selectedRole: parRole[0] }, () => this.fetchOps()) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedSalesStage")) {
                        let idNum = searchParams.get("selectedSalesStage").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perStage = this.state.salesStage.filter((stag) => stag.id === parseInt(idNum[id]))
                            selectedSalesStages.push(perStage[0])
                        }
                        // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                        // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedRoles")) {
                        let idNum = searchParams.get("selectedRoles").split(',')

                        for (let id = 0; id < idNum.length; id++) {
                            let perRole = this.state.roles.filter((rol) => rol.id === parseInt(idNum[id]))
                            selectedRoles.push(perRole[0])
                        }
                        // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                        // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedOwnerDelivery")) {
                        let idNum = searchParams.get("selectedOwnerDelivery").split(',')
                        for (let id = 0; id < idNum.length; id++) {
                            let perOD = this.state.ownerDelivery.filter((od) => od.id === parseInt(idNum[id]))
                            selectedOwnerDeliveries.push(perOD[0])
                        }
                        // let projectWithRegions = this.state.allProjects.filter(ele => ele.owner_region_sector === idNum)
                        // if (parRegion.length > 0) { this.setState({ selectedOwnerRegion: parRegion }, () => this.templateDataWithProjects(projectWithRegions)) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedOpportunity")) {
                        let idNum = searchParams.get("selectedOpportunity").split(',')
                        if (idNum[0] === '0' || idNum[0] === '') {
                            idNum.shift();
                        }

                        for (let id = 0; id < idNum.length; id++) {
                            let perOppoty = this.allOpportunity.filter((oppoty) => oppoty.id === parseInt(idNum[id]))
                            selectedOpportunities.push(perOppoty[0])
                        }
                        // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                        // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }
                    if (searchParams.get("selectedTimeDuration")) {
                        let idNum = searchParams.get("selectedTimeDuration")

                        let timedur = this.state.timeDuration.filter((tD) => tD.duration === parseInt(idNum))
                        selectedTimeDuration = timedur[0]

                        // let subSector = this.state.allSubSectors.filter((subSector) => subSector.id === idNum)
                        // if (subSector.length > 0) { this.setState({ selectedSubSector: subSector }) } else { window.history.pushState({ id: window.location.href }, '', `${window.location.protocol}//${window.location.host}/#Dashboard?comp=Reports&tab=2`) }
                    }

                    if (
                        selectedSalesStages.length > 0 ||
                        selectedRoles.length > 0 ||
                        selectedOpportunities.length > 0
                    ) {

                        //     selectedSalesStages,
                        //     selectedOwnerDeliveries,
                        //     selectedSalesStatuses,
                        //     selectedOpportunities)
                        this.setState({
                            selectedRegion: selectedRegions,
                            selectedSubSector: selectedSubSectors,
                            selectedRoles,
                            selectedSalesStage: selectedSalesStages,
                            selectedOwnerDelivery: selectedOwnerDeliveries,
                            selectedOpportunity: selectedOpportunities,
                            selectedTimeDuration,
                            loading: false

                        },
                            () => this.viewReport()
                        )
                    }
                    this.setState({
                        loading: false
                    },
                        () => this.viewReport()
                    )
                })

            })
            .catch(err => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ loading: false })
            })
    }

    filterChange = (name, value) => {
        let searchParams = new URLSearchParams(window.location.search)

        if (name === 'selectedRegion') {
            if (value.length > 0) {
                let selectedRegionIds = [];
                value.forEach((region) => {
                    selectedRegionIds.push(region.id)
                })
                if (searchParams.has("selectedRegion")) {
                    searchParams.set("selectedRegion", selectedRegionIds.join(","))
                } else {
                    searchParams.append("selectedRegion", selectedRegionIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedRegion")

            }
            let hash = window.location.hash
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);

        }

        if (name === 'selectedSubSector') {
            if (value.length > 0) {
                let selectedSubSectorIds = [];
                value.forEach((region) => {
                    selectedSubSectorIds.push(region.id)
                })
                if (searchParams.has("selectedSubSector")) {
                    searchParams.set("selectedSubSector", selectedSubSectorIds.join(","))
                } else {
                    searchParams.append("selectedSubSector", selectedSubSectorIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedSubSector")

            }
            let hash = window.location.hash
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);


        }

        if (name === 'selectedSalesStage') {
            if (value.length > 0) {
                let selectedSalesStageIds = [];
                value.forEach((region) => {
                    selectedSalesStageIds.push(region.id)
                })
                if (searchParams.has("selectedSalesStage")) {
                    searchParams.set("selectedSalesStage", selectedSalesStageIds.join(","))
                } else {
                    searchParams.append("selectedSalesStage", selectedSalesStageIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedSalesStage")

            }
            let hash = window.location.hash
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);


        }

        if (name === 'selectedRoles') {
            if (value.length > 0) {
                let selectedRolesIds = [];
                value.forEach((region) => {
                    selectedRolesIds.push(region.id)
                })
                if (searchParams.has("selectedRoles")) {
                    searchParams.set("selectedRoles", selectedRolesIds.join(","))
                } else {
                    searchParams.append("selectedRoles", selectedRolesIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedRoles")

            }
            let hash = window.location.hash
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);


        }

        if (name === 'selectedOwnerDelivery') {
            if (value.length > 0) {
                let selectedOwnerDeliveryIds = [];
                value.forEach((region) => {
                    selectedOwnerDeliveryIds.push(region.id)
                })
                if (searchParams.has("selectedOwnerDelivery")) {
                    searchParams.set("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
                } else {
                    searchParams.append("selectedOwnerDelivery", selectedOwnerDeliveryIds.join(","))
                }

            } else if (value.length === 0) {
                searchParams.delete("selectedOwnerDelivery")

            }
            let hash = window.location.hash
            let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

            window.history.pushState(null, '', newRelativePathQuery);
        }

        this.setState({ [name]: value }, () => this.filterOpportunity())
    }

    handleChange = (event) => {

        this.setState({ selectedFilter: event.target.value });
    };

    getSymbol(api) {
        return api.includes('?') ? '&' : '?'
    }

    setValue(e, value) {
        value.forEach(ele => {
            if (ele.name === "Select All") {
                // this.setState({selectedFilter : this.state.opportunity})
                value = this.state.opportunity
            }
        })
        let searchParams = new URLSearchParams(window.location.search)
        let selectedOpportunityIds = [];
        if (value.length > 0) {

            value.forEach((opport) => {
                selectedOpportunityIds.push(opport.id)
            })

            if (searchParams.has("selectedOpportunity")) {
                searchParams.set("selectedOpportunity", selectedOpportunityIds.join(","))
            } else {
                searchParams.append("selectedOpportunity", selectedOpportunityIds.join(","))
            }

        } else if (value.length === 0) {

            searchParams.delete("selectedOpportunity")

        }
        if (!searchParams.get("selectedOpportunity")) {

            searchParams.delete("selectedOpportunity")
        }

        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);



        this.setState({ selectedOpportunity: value });
    }

    handleTimeChange(e, value) {
        let searchParams = new URLSearchParams(window.location.search)
        if (value === null) {
            searchParams.delete("selectedTimeDuration")
        } else {
            if (searchParams.has("selectedTimeDuration")) {
                searchParams.set("selectedTimeDuration", value.duration)
            } else {
                searchParams.append("selectedTimeDuration", value.duration)
            }
        }
        let hash = window.location.hash
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString() + hash;

        window.history.pushState(null, '', newRelativePathQuery);


        this.setState({ selectedTimeDuration: value })
    }

    filterOpportunity() {
        let allProjects = [...this.allOpportunity]
        let filter1 = [];
        let filter2 = [];
        let filter3 = [];
        let filter4 = [];
        if (!this.state.selectedRegion || !this.state.selectedRegion.length) {
            filter1 = [...this.allOpportunity]
        }
        else {
            allProjects.forEach(ele => {
                for (let i = 0; i < this.state.selectedRegion.length; i++) {
                    if (this.state.selectedRegion[i].id === ele.owner_region_sector) { filter1.push(ele) }
                }
            })
        }

        if (!this.state.selectedSubSector || !this.state.selectedSubSector.length) {
            filter2 = [...filter1]
        }
        else {
            filter1.forEach(ele => {
                for (let i = 0; i < this.state.selectedSubSector.length; i++) {
                    if (this.state.selectedSubSector[i].id === ele.sub_sector) { filter2.push(ele) }
                }
            })
        }

        if (!this.state.selectedSalesStage || !this.state.selectedSalesStage.length) {
            filter3 = [...filter2]
        }
        else {
            filter2.forEach(ele => {
                for (let i = 0; i < this.state.selectedSalesStage.length; i++) {
                    if (this.state.selectedSalesStage[i].id === ele.sales_stage) { filter3.push(ele) }
                }
            })
        }

        if (!this.state.selectedOwnerDelivery || !this.state.selectedOwnerDelivery.length) {
            filter4 = [...filter3]
        }
        else {
            filter3.forEach(ele => {
                for (let i = 0; i < this.state.selectedOwnerDelivery.length; i++) {
                    if (this.state.selectedOwnerDelivery[i].id === ele.owner_delivery) { filter4.push(ele) }
                }
            })
        }

        // filter4.unshift({ name: 'Select All' });
        filter4.unshift({ name: 'Select All', id: 0 });
        this.setState({ opportunity: filter4 })
    }

    viewReport = () => {


        // if (
        //     this.state.selectedRegion.length === 0 &&
        //     this.state.selectedSubSector.length === 0 &&
        //     this.state.selectedSalesStage.length === 0 &&
        //     this.state.selectedOwnerDelivery.length === 0 &&
        //     this.state.selectedRoles.length === 0) {
        //     this.props.enqueueSnackbar('Please select any one field', {
        //         variant: 'info'
        //     });
        //     return;
        // }
        if (this.state.selectedOpportunity.length === 0) {
            this.props.enqueueSnackbar('Please select Projects', {
                variant: 'info'
            });
            return;
        }

        this.setState({ loading: true })
        let crms = []; this.state.selectedOpportunity.forEach(ele => { if (ele.id !== 0) crms.push(ele.id) })
        // if (crms.length) api = api + this.getSymbol(api) + 'crm=' + crms.join(',')

        let roles = []; this.state.selectedRoles.filter(ele => roles.push(ele.id))
        // if (roles.length) api = api + this.getSymbol(api) + 'role=' + roles.join(',')

        let status = []; this.state.selectedSalesStage.filter(ele => status.push(ele.id))
        // if (status.length) api = api + this.getSymbol(api) + 'status=' + status.join(',')

        let roles_crm_emp_uri = roles_sectors_regions + "?is_archive=0&";
        if (roles.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `role=${roles.join()}&`
        }
        if (crms.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `crm=${crms.join()}&`
        }
        if (status.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `status=${status.join()}&`
        }
        if (roles_crm_emp_uri[roles_crm_emp_uri.length - 1] === "&") {
            roles_crm_emp_uri = roles_crm_emp_uri.substring(0, roles_crm_emp_uri.length - 1)
        }

        Service.get(roles_crm_emp_uri,
            {
                headers: {
                    Authorization: "Token " + token,
                },
            })
            .then(res => {
                if (res.data.length === 0) {
                    this.props.enqueueSnackbar('No Resource found', {
                        variant: 'error'
                    });
                    this.setState({ loading: false })
                    return;
                }
                this.parseResData(res.data)
            })
            .catch(err => {

                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ loading: false })
            })
    }

    handleReload() {
        this.viewReport()
    }

    customCellcolor(rowData) {
        if (rowData.crm_status === "Closed/Won") {
            return { backgroundColor: '#9CCC65' };
        }
        if (rowData.crm_status === "Closed/Lost") {
            return { backgroundColor: '#F44336' };
        }
        if (rowData.crm_status === "Prospect") {
            return { backgroundColor: '#F8BBD0' };
        }
        if (
            rowData.crm_status === "Confirmed Invite" ||
            rowData.crm_status === "Lead" ||
            rowData.crm_status === "PQQ" ||
            rowData.crm_status === "Preferred Bidder" ||
            rowData.crm_status === "Tender"
        ) {
            return { backgroundColor: '#FFE57F' };
        }

        return {};
    }

    parseResData(data) {
        let parsedData = []
        let all_dates = []
        for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            let dates = data[i].dates;
            for (const date in dates) {
                if (all_dates.indexOf(date) === -1) {
                    all_dates.push(date);
                    obj = { ...obj, [date]: null }
                }
            }
            obj = { ...obj, ...dates }
            parsedData.push(obj)
        }
        let sorted = all_dates.sort(function (a, b) {
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
        });
        let columns = []

        columns.push({
            title: 'Project Number', field: 'crm_opportunity_id', width: 100, editable: 'never',
            cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
        });
        columns.push({
            title: 'Project Name', field: 'crm_name', width: 200, editable: 'never',
            cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
        })
        if (this.state.selectedRoles.length !== 1) {
            columns.push({
                title: 'Role', field: 'role_name', width: 150, editable: 'never',
                cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
            })
        }
        columns.push({
            title: 'Resource', width: 100,
            editable: 'never',
            field: 'emp_full_name',
            cellStyle: (cellData, rowData) => {
                return {
                    backgroundColor: '#CFD8DC'
                }
            }
        })
        columns.push({
            title: 'Stage', field: 'crm_status', width: 100, editable: 'never',
        })
        let startdate = moment().format('DD/MM/YYYY')
        let endDate = moment(startdate, "DD/MM/YYYY").add(this.state.selectedTimeDuration.duration, 'months').format('DD/MM/YYYY');
        this.sortedDates = []
        sorted.forEach(date => {
            if (moment(date, 'DD/MM/YYYY').isAfter(moment(startdate, 'DD/MM/YYYY')) && moment(date, 'DD/MM/YYYY').isBefore(moment(endDate, 'DD/MM/YYYY'))) {
                this.sortedDates.push(date)
                let newObj = {
                    title: date,
                    field: date,
                    type: 'number',
                    width: 70,
                    sorting: false,
                    headerStyle: {
                        transform: 'rotate(270deg)',
                        padding: '10px',
                        height: '100px'
                    },
                    cellStyle: {
                        textAlign: 'center'
                    },
                    filtering: false
                }
                columns.push(newObj)
            }
        })
        parsedData.sort(function (a, b) {
            if (a.crm_name < b.crm_name) { return -1; }
            if (a.crm_name > b.crm_name) { return 1; }
            return 0;
        })
        parsedData.forEach(rowData => {
            let emp_full_name = rowData.emp_first_name && rowData.emp_last_name ? rowData.emp_first_name + ' ' + rowData.emp_last_name : 'NA';
            rowData['emp_full_name'] = emp_full_name
        })

        this.setState({
            columns: columns,
            data: this.sortProjectAndroles(parsedData),
            loading: false,
            tableDialog: true
        })
    }

    sortProjectAndroles(allRoles) {

        let allIds = []
        allRoles.forEach(ele => {

            if (allIds.indexOf(ele.crm) === -1) {
                allIds.push(ele.crm)
            }
        });
        // let distinctProjcts = [...new Set(allIds)];
        let allRolesSorted = []
        for (let i = 0; i < allIds.length; i++) {
            let roles = []
            for (let j = 0; j < allRoles.length; j++) {
                if (allRoles[j].crm === allIds[i]) {
                    roles.push(allRoles[j])
                }
            }

            allRolesSorted.push(...this.rolesOrderSort(roles))

        }

        return allRolesSorted
    }

    rolesOrderSort(combinedRoleData) {
        let sortOrder = [
            {
                "id": 65,
                "current_combi_role": "Other",
                "job_family": "General_Management",
                "resource_type": "Ops_Staff",
                "job_roles": "Head of Operations",
            },
            {
                "id": 129,
                "current_combi_role": "PM",
                "job_family": "Operational_Specialists",
                "resource_type": "PM",
                "job_roles": "Senior Project Manager (SPM)",
            },
            {
                "id": 108,
                "current_combi_role": "PM",
                "job_family": "Operational_Specialists",
                "resource_type": "PM",
                "job_roles": "Project Manager (PM)",
            },
            {
                "id": 107,
                "current_combi_role": "Engineer",
                "job_family": "Operational_Specialists",
                "resource_type": "Ops_Staff",
                "job_roles": "Project Engineer",
            },
            {
                "id": 192,
                "current_combi_role": "Graduate 1 (New)",
                "job_family": "Project Engineer",
                "resource_type": "Project Engineer",
                "job_roles": "Graduate",
            },
            {
                "id": 135,
                "current_combi_role": "Site Manager",
                "job_family": "Operational_Specialists",
                "resource_type": "Ops_Staff",
                "job_roles": "Site Manager",
            },
            {
                "id": 124,
                "current_combi_role": "GF",
                "job_family": "Operational_Specialists",
                "resource_type": "Supervisory",
                "job_roles": "Senior General Foreman",
            },
            {
                "id": 58,
                "current_combi_role": "GF",
                "job_family": "Operational_Specialists",
                "resource_type": "Supervisory",
                "job_roles": "General Foreman",
            },
            {
                "id": 74,
                "current_combi_role": "Linesmen",
                "job_family": "Operational_Specialists",
                "resource_type": "OHL_Operatives",
                "job_roles": "Linesmen_Gang 1",
            },
            {
                "id": 18,
                "current_combi_role": "Civil",
                "job_family": "Operational_Specialists",
                "resource_type": "Supervisory",
                "job_roles": "Civil Supervisor",
            },
            {
                "id": 12,
                "current_combi_role": "Supervisor",
                "job_family": "Operational_Specialists",
                "resource_type": "Civils",
                "job_roles": "Civil Supervisor (DNO)",
            },
            {
                "id": 17,
                "current_combi_role": "Civil",
                "job_family": "Operational_Specialists",
                "resource_type": "Civils",
                "job_roles": "Civil Operative",
            },
            {
                "id": 11,
                "current_combi_role": "Civil_DNO",
                "job_family": "Operational_Specialists",
                "resource_type": "Civils",
                "job_roles": "Civil Operative (DNO)",
            },
        ]
        let rolesSorted = [];
        // For roles in sort order list
        for (let i = 0; i < sortOrder.length; i++) {
            for (let j = 0; j < combinedRoleData.length; j++) {
                if (combinedRoleData[j].role_id) {
                    if (combinedRoleData[j].role_id === sortOrder[i].id) {
                        rolesSorted.push(combinedRoleData[j])
                    }
                }
            }
        }


        // For other roles from order list 
        let unsortedRoles = []
        for (let i = 0; i < combinedRoleData.length; i++) {
            let matched = false
            for (let j = 0; j < rolesSorted.length; j++) {
                if (rolesSorted[j].id === combinedRoleData[i].id) {
                    matched = true
                }
            }
            if (!matched) {
                unsortedRoles.push(combinedRoleData[i])
            }
        }

        return [...rolesSorted, ...unsortedRoles];
    }

    onTableClose = () => {
        this.setState({ tableDialog: false })
    }

    render() {
        // const access = this.props.access.access;
        const classes = this.props.classes
        return (<>
            {this.state.loading ? <RotateCircleLoading color="#005D99" /> :
                <div style={{ padding: '10px' }}>
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <Autocomplete
                                multiple
                                id="region"
                                options={this.state.region}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedRegion', value)}
                                value={this.state.selectedRegion}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                )}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                multiple
                                id="Sub Sector"
                                options={this.state.subSector}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedSubSector', value)}
                                value={this.state.selectedSubSector}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    // <TextField {...params} variant="outlined" label="Sub Sector" placeholder="Sub Sector" />
                                    <TextField {...params} variant="outlined" label="Discipline" placeholder="Discipline" />
                                )}
                            />
                        </Grid>

                        <Grid item md={3}>
                            <Autocomplete
                                multiple
                                id="sales-stage"
                                options={this.state.salesStage}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedSalesStage', value)}
                                value={this.state.selectedSalesStage}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Sales Stage" placeholder="sales Stage" />
                                )}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                multiple
                                id="role"
                                options={this.state.roles}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.job_roles}
                                onChange={(e, value) => this.filterChange('selectedRoles', value)}
                                value={this.state.selectedRoles}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.job_roles}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Role" placeholder="Role" />
                                )}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                multiple
                                id="owner_delivery"
                                options={this.state.ownerDelivery}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.filterChange('selectedOwnerDelivery', value)}
                                value={this.state.selectedOwnerDelivery}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Operations Lead" placeholder="Operations Lead" />
                                )}
                            />
                        </Grid>
                        <Grid item md={7}>
                            <Autocomplete
                                multiple
                                id="projects"
                                options={this.state.opportunity}
                                disableCloseOnSelect
                                getOptionLabel={(option) => {
                                    return option.name ? option.name : ''
                                }}
                                onChange={(e, value) => this.setValue(e, value)}
                                value={this.state.selectedOpportunity}
                                size="small"
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Project" placeholder="Project" />
                                )}
                            />
                        </Grid>

                        <Grid item md={2} >
                            <Autocomplete
                                id="timeDuration"
                                options={this.state.timeDuration}
                                // disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => this.handleTimeChange(e, value)}
                                value={this.state.selectedTimeDuration}
                                size="small"
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Time Duration" placeholder="Time Duration" />
                                )}
                            />
                        </Grid>
                        <Grid item md={12} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                // size="medium"
                                color="primary"
                                onClick={this.viewReport}
                            >Get Resources</Button>
                        </Grid>
                    </Grid>
                    {/* <Grid>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={age}
                                onChange={handleChange}
                                label="Age"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}
                    {this.state.tableDialog === true ?
                        <Dialog fullScreen open={this.state.tableDialog} onClose={this.onTableClose} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onTableClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Combined Resource
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <ResourceTable
                                reload={this.handleReload}
                                tableDialog={this.state.tableDialog}
                                onClose={this.onTableClose}
                                data={this.state.data}
                                columns={this.state.columns}
                                selectedTimeDuration={this.state.selectedTimeDuration}
                                selectedOpportunity={this.state.selectedOpportunity}
                                selectedSalesStage={this.state.selectedSalesStage}
                                selectedRoles={this.state.selectedRoles}
                                access={this.props.access.access}
                                sortedDates={this.sortedDates}
                            />
                        </Dialog> : null}
                </div>
            }

        </>)
    }
}

export default withSnackbar(withStyles(useStyles)(PRPLiveChange))