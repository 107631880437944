import { withSnackbar } from 'notistack';
import React, { Component, createRef } from 'react';
import Service from '../../config/networkutils';
import MaterialTable from 'material-table';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@material-ui/core';
import moment from 'moment';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CloseIcon from '@material-ui/icons/Close';
import LeaveStatus from './LeaveStatus';
import { getCookieNoDecrypt } from '../../utils/CookieHelper'
import { getClientID } from '../../utils/reuseFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class LeaveApproval extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: true,
            open: false,
            selectedData: null
        }
        this.refgetData = createRef()
        this.handleReload = this.handleReload.bind(this)
    }

    getData() {
        Service.get(`/api/app/employee/get_leave_requests_to_lm/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            this.setState({ data: res.data, isLoading: false }, () => {
            })
        })
            .catch(error => {
                console.log(error)
                if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data.message, { variant: 'error' })
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
                }
                this.setState({ isLoading: false })
            })
    }


    componentDidMount() {
        this.getData()
    }

    handleReload() {
        this.getData()
    }

    render() {

        const handleClose = () => {
            this.setState({ open: false });
        }

        return (
            <div>
                <Box margin={2}>
                    <MaterialTable
                        columns={[{
                            title: 'Employee Name', field: 'emp_first_name',
                            render: rowData => rowData.request_by_first_name + ' ' + rowData.request_by_last_name
                        }, {
                            title: 'Requested On', field: 'requested_on',
                            render: rowData => rowData.requested_on ? moment(rowData.requested_on).format('DD-MM-YYYY') : ''
                        }, {
                            title: 'Status', field: 'approval_status',
                            lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Partially Approved', 4: "Missing" }
                        }, {
                            title: 'Replied On', field: 'approved_on',
                            render: rowData => rowData.approved_on ? moment(rowData.approved_on).format('DD-MM-YYYY') : ''
                        }
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        title="Leave Requests"
                        options={{
                            paging: false,
                            fontSize: '7',
                        }}
                        actions={[{
                            icon: () => <OpenInBrowserIcon />,
                            tooltip: 'View',
                            onClick: (event, rowData) => {
                                this.setState({ selectedData: rowData }, () => {
                                    this.setState({
                                        open: true
                                    })
                                });
                            }
                        }]}
                    />
                </Box>

                <Dialog fullScreen open={this.state.open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {this.state.selectedData?.request_by_first_name + ' ' + this.state.selectedData?.request_by_last_name + "'s Leave Request"}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box marginTop={'10px'}>
                        <LeaveStatus data={this.state.selectedData} reload={this.handleReload} mobile_view={true} />
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(LeaveApproval);