import { Box, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import Auth from '../../utils/authenticate';
import Service from '../../config/networkutils';
import Region from './Region';



const token = Auth.token();

class County extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            roles: {},
            loading: true
        }
        this.handleRefresh = this.handleRefresh.bind(this)
    }

    getData() {
        Service.get(this.props.api, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ data: res.data, loading: false })
        }).catch(error => {
            this.props.enqueueSnackbar('Something went wrong!', {
                variant: 'error'
            });
        })
    }

    postData(newData) {
        if (newData.name.length === 0) {
            this.props.enqueueSnackbar(this.props.title + 'Name cannot be Empty', {
                variant: 'success'
            });
            return
        } else {
            Service.post(this.props.api, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            }).then(res => {
                this.getData()
                this.props.enqueueSnackbar('Added Successfully', {
                    variant: 'success'
                });
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }
    }

    editData(newData) {
        if (newData.name.length === 0) {
            this.props.enqueueSnackbar(this.props.title + 'Name cannot be Empty', {
                variant: 'warning'
            });

        } else {
            Service.put(this.props.api + newData.id + "/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: newData
            }).then(res => {
                this.getData()
                this.props.enqueueSnackbar('Edited Successfully', {
                    variant: 'success'
                });
            }).catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
        }

    }



    componentDidMount() {
        this.getData()
    }

    handleRefresh() {
        this.setState({
            loading: true
        })
        this.getData()
    }

    render() {
        return (
            <div>
                <Paper elevation={1}>
                    <MaterialTable
                        title={this.props.title}
                        columns={[{ title: 'Name', field: 'name' }]}
                        data={this.state.data}
                        isLoading={this.state.loading}
                        detailPanel={rowData => {
                            return (
                                <div>
                                    <Box margin={2}>
                                    <Region api={'/api/region/'}
                                        title={'Region'}
                                        rowData={rowData}
                                        parameter={'country'}
                                    />
                                    </Box>
                                </div>
                            )
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Object.keys(newData).length === 0) {
                                            this.props.enqueueSnackbar(this.props.title + 'Name cannot be Empty', {
                                                variant: 'warning'
                                            });
                                            reject()
                                        } else {
                                            this.postData(newData)
                                            resolve();
                                        }
                                    }, 1000);
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Object.keys(newData).length === 0) {
                                            this.props.enqueueSnackbar(this.props.title + 'Name cannot be Empty', {
                                                variant: 'warning'
                                            });
                                            reject()
                                        } else {
                                            this.editData(newData)
                                            resolve();
                                        }
                                    }, 1000);
                                }),
                            //       onRowDelete: oldData =>
                            //           new Promise((resolve, reject) => {
                            //               setTimeout(() => {

                            //                   this.deleteOps(oldData.id)

                            //                   resolve();
                            //               }, 1000);
                            //           })
                        }}
                    >

                    </MaterialTable>
                </Paper>
            </div>
        )
    }
}

export default withSnackbar(County);
