import { Box, Button, Checkbox, Dialog, DialogContent, Grid, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CoffeeLoading } from 'react-loadingg';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment/moment';
import Service from '../../../config/networkutils';
import { getCookie } from '../../../utils/CookieHelper';
import { getPrimaryColor } from '../../Helper/CommonThings';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const token = getCookie("ptd_ts_token")
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class StandBy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            coffeeLoading: false,
            notiDialog: false,
            tabValue: 0,
            roleGroup: [],
            allRoles: [],
            allOwnerRegions: [],
            allOwnerDeliveryUnits: [],
            allSubSectors: [],
            selectedRole: [],
            selectedOwnerRegion: [],
            selectedOwnerDelivery: [],
            selectedSubSector: [],
            selectedRoleGroup: [],
            allEmp: [],
            selectedEmployee: [],
        };
    }

    getDropdownData() {
        Service.get('/api/roles/get_default_data/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    allRoles: res.data.roles,
                    allOwnerRegions: res.data.owner_region_sector,
                    allOwnerDeliveryUnits: res.data.owner_delivery,
                    allSubSectors: res.data.sub_sector,
                    roleGroup: res.data.role_group,
                    allEmp: res.data.employees,
                }, () => {
                    this.setState({ notiDialog: false });
                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })

    }

    componentDidMount() {
        this.getDropdownData();
    }

    standByReport = () => {

        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please select the Dates!', {
                variant: 'warning'
            });
            return;
        }

        this.setState({ coffeeLoading: true });

        Service.get(`/api/ptd/employees/emp_standby/?start=${this.state.startDate}&end=${this.state.endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('StandBy Report generated successfully!', {
                    variant: 'success'
                });
                this.setState({ notiDialog: true });
            })
            .catch(error => {
                console.log(error);
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    leaveReport = () => {

        let url = '/api/ptd/employees/emp_leave_report/'

        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please select the Dates!', {
                variant: 'warning'
            });
            return;
        }

        if (this.state.startDate) {
            url += `?start=${this.state.startDate}`
        }

        if (this.state.endDate) {
            url += `&end=${this.state.endDate}`
        }

        if (this.state.selectedSubSector.length > 0) {
            url += `&sector=${this.state.selectedSubSector.map(item => item.id)}`
        }

        if (this.state.selectedOwnerRegion.length > 0) {
            url += `&region=${this.state.selectedOwnerRegion.map(item => item.id)}`
        }

        if (this.state.selectedOwnerDelivery.length > 0) {
            url += `&delivery=${this.state.selectedOwnerDelivery.map(item => item.id)}`
        }

        if (this.state.selectedRole.length > 0) {
            url += `&role=${this.state.selectedRole.map(item => item.id)}`
        }

        if (this.state.selectedRoleGroup.length > 0) {
            url += `&role_group=${this.state.selectedRoleGroup.map(item => item.id)}`
        }

        if (this.state.selectedEmployee.length > 0) {
            url += `&emp=${this.state.selectedEmployee.map(item => item.id)}`
        }

        url += '&is_active=True'

        this.setState({ coffeeLoading: true });
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.status === 204) {
                    this.props.enqueueSnackbar('No data found!', {
                        variant: 'warning'
                    });
                    this.setState({ coffeeLoading: false });
                } else {
                    this.setState({ coffeeLoading: false });
                    this.props.enqueueSnackbar('Leave Report generated successfully!', {
                        variant: 'success'
                    });
                    this.setState({ notiDialog: true });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    otherReportLogs(){
        let url = '/api/ptd/employees/other_report_log/'

        if (!this.state.startDate || !this.state.endDate) {
            this.props.enqueueSnackbar('Please select the Dates!', {
                variant: 'warning'
            });
            return;
        }

        if (this.state.startDate) {
            url += `?start=${this.state.startDate}`
        }

        if (this.state.endDate) {
            url += `&end=${this.state.endDate}`
        }

        if (this.state.selectedSubSector.length > 0) {
            url += `&sector=${this.state.selectedSubSector.map(item => item.id)}`
        }

        if (this.state.selectedOwnerRegion.length > 0) {
            url += `&region=${this.state.selectedOwnerRegion.map(item => item.id)}`
        }

        if (this.state.selectedOwnerDelivery.length > 0) {
            url += `&delivery=${this.state.selectedOwnerDelivery.map(item => item.id)}`
        }

        if (this.state.selectedRole.length > 0) {
            url += `&role=${this.state.selectedRole.map(item => item.id)}`
        }

        if (this.state.selectedRoleGroup.length > 0) {
            url += `&role_group=${this.state.selectedRoleGroup.map(item => item.id)}`
        }

        if (this.state.selectedEmployee.length > 0) {
            url += `&emp=${this.state.selectedEmployee.map(item => item.id)}`
        }

        url += `&is_active=True`

        this.setState({ coffeeLoading: true });
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                if (res.status === 204) {
                    this.props.enqueueSnackbar('No data found!', {
                        variant: 'warning'
                    });
                    this.setState({ coffeeLoading: false });
                } else {
                    this.setState({ coffeeLoading: false });
                    this.props.enqueueSnackbar('Leave Report generated successfully!', {
                        variant: 'success'
                    });
                    this.setState({ notiDialog: true });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({ coffeeLoading: false });
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }


    getReport = () => {
        if (this.state.tabValue === 0) {
            this.standByReport();
        } else if(this.state.tabValue === 1) {
            this.leaveReport();
        }else{
            this.otherReportLogs()
        }
    }

    handleSubSectorChange = (e, value) => {
        this.setState({ selectedSubSector: value });
    }

    handleRegionChange = (key, value) => {
        this.setState({ [key]: value });
    }

    handleOwnerDeliveryChange = (e, value) => {
        this.setState({ selectedOwnerDelivery: value });
    }

    handleRoleChange = (e, value) => {
        this.setState({ selectedRole: value });
    }

    handleRoleGroupChange = (e, value) => {
        this.setState({ selectedRoleGroup: value });
    }

    handleEmployeeChange = (e, value) => {
        this.setState({ selectedEmployee: value });
    }

    render() {

        const handleStartDateChange = (date) => {
            this.setState({ startDate: moment(date).format("YYYY-MM-DD") });
        };
        const handleEndDateChange = (date) => {
            this.setState({ endDate: moment(date).format("YYYY-MM-DD") });
        };
        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        }

        return (
            <div>
                {this.state.notiDialog ?
                    <Typography variant="subtitle1" >
                        Report will be sent to your email shortly!
                    </Typography> :
                    <Box>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <Tabs
                                value={this.state.tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabChange}
                                aria-label="disabled tabs example"
                            >
                                <Tab label="Standby" />
                                <Tab label="Other Report By User" />
                                <Tab label='Other Report By Logs' />
                            </Tabs>
                        </Box>
                        {this.state.tabValue === 1 || this.state.tabValue === 2 ?
                            <div>
                                <Grid container spacing={1} style={{ marginBottom: '20px', marginTop: '10px' }} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="sub-sector"
                                            limitTags={1}
                                            options={this.state.allSubSectors}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => this.handleSubSectorChange(e, value)}
                                            value={this.state.selectedSubSector}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Sector" placeholder="Sector" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="owner-region-sector"
                                            limitTags={1}
                                            options={this.state.allOwnerRegions}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => this.handleRegionChange('selectedOwnerRegion', value)}
                                            value={this.state.selectedOwnerRegion}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Region" placeholder="Region" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="owner-delivery-unit"
                                            limitTags={1}
                                            options={this.state.allOwnerDeliveryUnits}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => this.handleOwnerDeliveryChange(e, value)}
                                            value={this.state.selectedOwnerDelivery}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Operations Lead" placeholder="Operations Lead" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} style={{ marginBottom: '20px', marginTop: '10px' }} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="roles"
                                            limitTags={1}
                                            options={this.state.allRoles}
                                            getOptionLabel={(option) => option.job_roles}
                                            onChange={(e, value) => this.handleRoleChange(e, value)}
                                            disableCloseOnSelect
                                            value={this.state.selectedRole}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.job_roles}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Role" placeholder="Role" />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="roleGroup"
                                            limitTags={1}
                                            options={this.state.roleGroup}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => this.handleRoleGroupChange(e, value)}
                                            disableCloseOnSelect
                                            value={this.state.selectedRoleGroup}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Role Group" placeholder="Role Group" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            multiple
                                            id="Employee"
                                            options={this.state.allEmp}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
                                            onChange={(e, value) => this.handleEmployeeChange(e, value)}
                                            value={this.state.selectedEmployee}
                                            size="small"
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.first_name + ' ' + option.last_name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (

                                                <TextField {...params} variant="outlined" label="Employee" placeholder="Employee" />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            : null}
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Start Date"
                                    inputVariant="outlined"
                                    size='small'
                                    format="dd/MM/yyyy"
                                    style={{ minWidth: '200px' }}
                                    value={this.state.startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog2"
                                    label="End Date"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    size='small'
                                    minDate={new Date(this.state.startDate)}
                                    minDateMessage={"Start Date should be lesser than End Date"}
                                    style={{ marginLeft: '10px', minWidth: '200px' }}
                                    value={this.state.endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mt={'10px'}>
                            <Button color='primary' size='small' variant='contained' style={{ marginRight: "10px" }} onClick={() => this.getReport()}>
                                Get Report
                            </Button>
                        </Box>
                    </Box>

                }

                <Dialog open={this.state.coffeeLoading} maxWidth={"md"}>
                    <DialogContent style={{ padding: "70px" }}>
                        <CoffeeLoading color={getPrimaryColor()} />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(StandBy);