import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

const token = getCookie("ptd_ts_token");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class UserAsLM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            employeeData: this.props.employeeData,
            data: [],
            selectedLineManager: [],
            linkDialog: false,
            linkDisabled: false,
            userData: this.props.userData,
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        Service.get(`/api/ptd/employees/get_employees_of_lm/?line_manager=${this.props.userData.id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        }).then(res => {
            this.setState({ data: res.data, isLoading: false })
        })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    handleLineManagerChange = (event, value) => {
        this.setState({ selectedLineManager: value })
    }

    linkLineManager() {
        if (this.state.selectedLineManager.length === 0) {
            this.props.enqueueSnackbar('Please select a Line Manager!', {
                variant: 'warning'
            })
            return
        } else if (this.state.selectedLineManager.map(item => item.id).includes(this.state.userData.id)) {
            this.props.enqueueSnackbar('Employee can not be his/her own Line Manager!', {
                variant: 'warning'
            })
            return
        } else {
            this.setState({
                linkDisabled: true
            })
            Service.patch('/api/ptd/employees/link_employee_to_linemanager/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    item_id: this.state.selectedLineManager.map(item => item.id),
                    linemanager_id: this.state.userData.id
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Line Manager linked successfully!', {
                        variant: 'success'
                    })
                    this.setState({
                        linkDialog: false,
                        selectedLineManager: [],
                        linkDisabled: false
                    }, () => {
                        if (this.props.reloadEmployeeData) {
                            this.props.reloadEmployeeData()
                        }
                        this.fetchData()
                    })
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                })
        }
    }

    render() {
        return (
            <div>
                <MaterialTable
                    title={''}
                    columns={[
                        {
                            title: 'Name', field: 'first_name',
                            render: rowData => rowData.first_name + ' ' + rowData.last_name
                        }, {
                            title: 'Email', field: 'email',
                        }
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{ paging: false }}
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Link Employees',
                            isFreeAction: true,
                            onClick: () => this.setState({ linkDialog: true })
                        }
                    ]}
                    editable={
                        {
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    Service.patch(`/api/ptd/employees/delink_employee_from_linemanager/`, {
                                        headers: {
                                            Authorization: "Token " + token,
                                        },
                                        data: {
                                            item_id: oldData.id,
                                        }
                                    })
                                        .then(res => {
                                            this.props.enqueueSnackbar('Employee unlinked successfully!', {
                                                variant: 'success'
                                            })
                                            this.fetchData()
                                            resolve()
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            this.props.enqueueSnackbar('Something went wrong!', {
                                                variant: 'error'
                                            })
                                            reject()
                                        })
                                })
                        }
                    }
                />

                <Dialog fullWidth={true} maxWidth="xs" open={this.state.linkDialog} onClose={() => this.setState({ linkDialog: false })}>
                    <DialogTitle>
                        <Box display='flex' justifyContent='space-between' alignItems='center' >
                            <Typography variant="h6">{this.state.userData?.first_name + ' ' + this.state.userData?.last_name}</Typography>
                            <CloseIcon onClick={() => this.setState({ linkDialog: false })} style={{ float: 'right', color: 'red' }} />
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <Autocomplete
                                id="line_manager"
                                value={this.state.selectedLineManager}
                                options={this.state.employeeData}
                                multiple
                                getOptionLabel={(option) => option?.first_name + " " + option?.last_name}
                                isOptionEqualToValue={(option, value) => value && option.id === value}
                                fullWidth
                                onChange={this.handleLineManagerChange}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.first_name + " " + option?.last_name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => <TextField variant="outlined" {...params} label="Select Employees" />}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size='small'
                            variant="contained"
                            color="primary"
                            onClick={() => this.linkLineManager()}
                            disabled={this.state.linkDisabled}>
                            Link
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withSnackbar(UserAsLM);