import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LeaveRestriction from './LeaveRestriction';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


class LeaveRestrictionTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            years: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        var years = []

        for (let index = 2025; index <= moment().year() + 1; index++) {
            years.push(index)
        }

        this.setState({
            years: years,
            value: years.length - 2
        })
    }
    render() {

        const handleChange = (event, newValue) => {
            this.setState({
                value: newValue
            })
        };

        return (
            <div>
                <AppBar position="static" color="default">
                    <Tabs value={this.state.value} onChange={handleChange} aria-label="simple tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {this.state.years.map((value, index) =>
                            <Tab label={this.state.years[index]} {...a11yProps(index)} />
                        )}
                    </Tabs>
                </AppBar>
                {this.state.years.map((value, index) =>
                    <TabPanel value={this.state.value} index={index}>
                        <LeaveRestriction year={this.state.years[index]} employeeData={this.props.employeeData} />
                    </TabPanel>
                )}
            </div>
        );
    }
}

export default withSnackbar(LeaveRestrictionTabs);