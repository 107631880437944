import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ApplyLeaves from './ApplyLeaves';
import { Box } from '@material-ui/core';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import TodayIcon from '@material-ui/icons/Today';
import MyLeaves from './MyLeaves';
import LeaveLineManager from '../Leaves/LeaveLineManager';
import { getCookie } from '../../utils/CookieHelper';

var tabs = ['Dashboard', 'Leave Requests']

const access = JSON.parse(getCookie("ptd_access"))

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

class MobileViewDrawer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            index: 'Dashboard'
        }
    }

    componentDidMount() {
        if (access && access.access && access.access.l11a && access.access.l11a.l11a_b) {
            tabs.push('Leave Approval')
        } else {
            tabs = ['Dashboard', 'Leave Requests']
        }
    }


    render() {

        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({ open: true });
        };

        const handleDrawerClose = () => {
            this.setState({ open: false });
        };
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, this.state.open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            PT&D Resource Management
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={this.state.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {['Dashboard', 'Leave Requests', 'Leave Approval'].map((text, index) => (
                            <ListItem button key={text} onClick={() => this.setState({ index: text, open: false })}>
                                <ListItemIcon>{text === 'Leave Approval' ? <InsertInvitationIcon /> : text === 'Leave Requests' ? <PermContactCalendarIcon /> : text === 'Dashboard' ? <TodayIcon /> : null}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: this.state.open,
                    })}
                >
                    <div className={classes.drawerHeader} />
                    <Box marginTop={10}>
                        {this.state.index === 'Leave Approval' ? <LeaveLineManager /> :
                            this.state.index === 'Leave Requests' ? <MyLeaves /> : this.state.index === 'Dashboard' ? <ApplyLeaves /> : null}
                    </Box>
                </main>
            </div>
        );
    }
}

export default withStyles(useStyles)(MobileViewDrawer);
