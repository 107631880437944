import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import { getCookie } from '../../utils/CookieHelper';
import { Box, Button, TextField } from '@material-ui/core';
import Service from '../../config/networkutils'
import { Autocomplete } from '@mui/material';

const token = getCookie("ptd_ts_token");

class LinkLineManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linkDisabled: false,
            employeeData: this.props.employeeData,
            selectedLineManager: !this.props.selectedLineManager ? null : this.props.selectedLineManager,
            userData: this.props.userData,
        }
    }

    linkLineManager() {
        if (!this.state.selectedLineManager) {
            this.props.enqueueSnackbar('Please select a Line Manager!', {
                variant: 'warning'
            })
            return
        } else if (this.state.selectedLineManager.id === this.state.userData.id) {
            this.props.enqueueSnackbar('Employee can not be his/her own Line Manager!', {
                variant: 'warning'
            })
            return
        } else {
            this.setState({
                linkDisabled: true
            })
            Service.patch('/api/ptd/employees/link_employee_to_linemanager/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    item_id: [this.state.userData.id],
                    linemanager_id: this.state.selectedLineManager.id
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Line Manager linked successfully!', {
                        variant: 'success'
                    })
                    this.setState({
                        // selectedLineManager: null,
                        linkDisabled: false
                    }, () => {
                        if (this.props.reloadEmployeeData) {
                            this.props.reloadEmployeeData()
                        }
                    })
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    })
                })
        }
    }

    handleLineManagerChange = (e, val) => {
        this.setState({
            selectedLineManager: val
        })
    }

    render() {
        return (
            <div>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' m={2}>
                    <Autocomplete
                        style={{ marginBottom: '10px' }}
                        id="line_manager"
                        value={this.state.selectedLineManager}
                        options={this.state.employeeData}
                        getOptionLabel={(option) => option?.first_name + " " + option?.last_name}
                        isOptionEqualToValue={(option, value) => value && option.id === value}
                        fullWidth
                        onChange={this.handleLineManagerChange}
                        renderInput={(params) => <TextField variant="outlined" {...params} label="Select Line Manager" />}
                    />
                    <Button
                        size='small'
                        variant="contained"
                        color="primary"
                        onClick={() => this.linkLineManager()}
                        disabled={this.state.linkDisabled}>
                        Link Line Manager
                    </Button>
                </Box>
            </div>
        );
    }
}

export default withSnackbar(LinkLineManager);