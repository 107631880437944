import React, { Component } from "react";
import Service from "../../config/networkutils";
import {
  // employees_and_projects,
  prp_template_api_bulk_update,
  prp_template_api,
  prp_roles_api,
  sub_sector_api,
  conditionalPRPTandPRPRcreate
} from "../../config/apiList";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { getCookie } from "../../utils/CookieHelper";
import { Button, Grid, Divider } from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import {
  // weekStartDates,
  weekStartDatesObject,
} from "../../utils/betweenDates";
import RemoveIcom from "@material-ui/icons/RemoveCircle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import moment from "moment";
// import { Add } from "@material-ui/icons";
import { findHighestToDate, findLowestFromDate } from "../../utils/reuseFunctions";
import SubContractorProjectsSchedulesGraph from "./SubContractorProjectsSchedulesGraph";

const token = getCookie("ptd_ts_token");
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  textFieldContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  itemMargin: {
    margin: "10px",
  },
  appBarMargin: {
    // marginTop: "70px",
    marginTop: "0px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    float: "right",
  },
  customGridStyle: {
    margin: 0,
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
  },
  date: {
    margin: 0,
    width: "100%",
    "margin-top": "5px",
  },
});
class SubContractorProjectsSchedules extends Component {
  state = {
    rawEmployeeSchedules: [],
    chartDuration: [],
    showChart: false,
    aldat: [],
    allWeekDates: [],
    series: [],
    graphRerender: true,
    assignDialog: false,
    selectedRole: '',
    selectedCrm: '',
    disciplineArr: [],
    deletedSchedules: [],
    workType: [],
  };

  getDiscipline() {
    return new Promise((resolve, reject) => {
      Service.get(sub_sector_api, {
        headers: {
          Authorization: "Token " + token,
        },
      }).then(res => resolve(res.data)).catch(error => reject(error))
    })
  }

  applyQuantityToEveryWeek = async () => {
    let rawEmployeeScheduless = [...this.state.rawEmployeeSchedules];
    for (
      // Iterating all the projects
      let project = 0;
      project < rawEmployeeScheduless.length;
      project++
    ) {
      let stDat = findLowestFromDate(rawEmployeeScheduless[project]);
      let enDat = findHighestToDate(rawEmployeeScheduless[project]);

      let allMondayDates = weekStartDatesObject(
        stDat.toString(),
        enDat.toString()
      );

      for (
        let shdl = 0;
        shdl < rawEmployeeScheduless[project].shdl_data.length;
        shdl++
      ) {
        // Iterating all the shdls

        for (let monday in allMondayDates) {
          // Iterating all the monday dates for one shdl

          if (
            moment(monday, "DD/MM/YYYY").isSameOrAfter(
              moment(
                rawEmployeeScheduless[project].shdl_data[shdl].from.slice(0, 10)
              )
            ) &&
            moment(monday, "DD/MM/YYYY").isSameOrBefore(
              moment(
                rawEmployeeScheduless[project].shdl_data[shdl].to.slice(0, 10)
              )
            )
          ) {
            allMondayDates[monday] =
              Number(allMondayDates[monday]) +
              Number(rawEmployeeScheduless[project].shdl_data[shdl].qty);
          }
        }
      }
      rawEmployeeScheduless[project].dates = allMondayDates;
    }
    this.setState({ rawEmployeeSchedules: rawEmployeeScheduless });
    this.generateGraphData(rawEmployeeScheduless);
    this.setState({ showChart: true }, () => this.updateEmployeeModifiedSchedules());

  };

  handleFromDate = (date, i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    // if (date && moment(date).isAfter(moment(rawEmployeeSchedules[i].shdl_data[j].to))) {
    //   this.props.enqueueSnackbar('From date should be before To date', {
    //     variant: 'warning'
    //   });
    //   return
    // }
    if (date && moment(date).format('dddd') !== 'Monday') {
      this.props.enqueueSnackbar('Please select Monday as From date', {
        variant: 'warning'
      });
    } else {
      rawEmployeeSchedules[i].shdl_data[j].from = moment(date).format('YYYY-MM-DD')
      this.setState({ rawEmployeeSchedules })
    }
  }

  handleToDate = (date, i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    // if (date && moment(date).isBefore(moment(rawEmployeeSchedules[i].shdl_data[j].from))) {
    //   this.props.enqueueSnackbar('To date should be after From date', {
    //     variant: 'warning'
    //   });
    //   return
    // }
    if (date && moment(date).format('dddd') !== 'Monday') {
      this.props.enqueueSnackbar('Please select Monday as To date', {
        variant: 'warning'
      });
    } else {
      rawEmployeeSchedules[i].shdl_data[j].to = moment(date).format('YYYY-MM-DD')
      this.setState({ rawEmployeeSchedules })
    }
  }

  handleQty = (e, i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    rawEmployeeSchedules[i].shdl_data[j].qty = !e.target.valueAsNumber ? e.target.value : e.target.valueAsNumber
    this.setState({ rawEmployeeSchedules })
  }

  handleWorkType = (e, i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    rawEmployeeSchedules[i].shdl_data[j].work_type = e.target.value
    this.setState({ rawEmployeeSchedules })
  }

  removeSchedule = (i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    let deletedSchedules = this.state.deletedSchedules
    deletedSchedules.push(rawEmployeeSchedules[i].shdl_data[j].id)
    rawEmployeeSchedules[i].shdl_data.splice(j, 1)
    this.setState({ rawEmployeeSchedules, deletedSchedules })
  }

  handleQtyCheck = (i, j) => {
    let rawEmployeeSchedules = this.state.rawEmployeeSchedules
    if (rawEmployeeSchedules[i].shdl_data[j].qty === '') {
      rawEmployeeSchedules[i].shdl_data[j].qty = 0
      this.setState({ rawEmployeeSchedules })
    }
  }

  updateEmployeeModifiedSchedules = () => {
    Service.patch(prp_template_api_bulk_update, {
      headers: {
        Authorization: "Token " + token,
      },
      data: this.state.rawEmployeeSchedules,
    })
      .then((res) => {
        // this.props.employeeUpdate(res.data);
        this.props.enqueueSnackbar("Added Successfully", {
          variant: "success",
        });
        // this.props.unmountMe();
        this.setState({ assignDialog: false }, () => {
          if (this.props.reload) {
            this.props.reload()
          }
        })

      })
      .catch((error) => {
        this.props.enqueueSnackbar("Updating employee schedules failed!", {
          variant: "error",
        });
      });
  };

  getWeekOfDates = (startDate, endDate) => {
    const dayINeed = 1; // for Monday

    let currDate = moment(startDate).startOf("day");
    let lastDate = moment(endDate).startOf("day");
    let dates = [["Weeks"]];

    while (currDate.diff(lastDate) <= 0) {
      if (currDate.isoWeekday() === dayINeed) {
        dates.push([currDate.format("DD/MM/YYYY")]);
      }
      currDate.add(1, "days");
    }
    return dates;
  };

  setWeekdates(startDate, endDate) {
    let allWeekDates = this.getWeekOfDates(startDate, endDate);
    this.setState({ chartDuration: [startDate, endDate], allWeekDates });
  }

  generateGraphData(empProjectsArray) {

    let allWeekDatess = this.getWeekOfDates(this.state.chartDuration[0],
      this.state.chartDuration[1]);
    // let allWeekDatess = this.setWeekdates(
    //   this.state.chartDuration[0],
    //   this.state.chartDuration[1]
    // );


    for (let i = 0; i < empProjectsArray.length; i++) {
      // Iterates all the projects
      allWeekDatess[0].push(empProjectsArray[i].crm_name);
      for (
        let j = 1;
        j < allWeekDatess.length;
        j++ // Iterates all the weekdate of the graph weeks
      ) {
        let found = false;
        for (let key in empProjectsArray[i].dates) {
          // Iterates all the weekdate of the projects
          if (allWeekDatess[j][0] === key) {
            allWeekDatess[j].push(Number(empProjectsArray[i].dates[key]));
            found = true;
            break;
          }
        }
        if (!found) {
          allWeekDatess[j].push(Number(0.0));
        }
      }
    }
    for (
      let h = 1;
      h < allWeekDatess.length;
      h++ // Iterates all the weekdate of the graph weeks
    ) {
      let allweekQties = allWeekDatess[h].slice(1);
      allWeekDatess[h].splice(
        1,
        0,
        allweekQties.reduce((total, num) => total + num, 0.0)
      );
    }
    allWeekDatess[0].splice(1, 0, "Total");
    let apexGraphTranspose = allWeekDatess[0].map((col, i) =>
      allWeekDatess.map((row) => row[i])
    );
    let series = [];
    apexGraphTranspose.map((everyLine) => {
      series.push({
        name: everyLine[0],
        data: everyLine.slice(1, everyLine.length),
      });
      return null;
    });
    this.setState({ allWeekDates: allWeekDatess, dateLabels: series[0].data, series: series.slice(1, series.length) }, () => this.setState({ graphRerender: true }));
  }

  getEmployeeProjects() {
    this.setState({ isLoading: true });
    // let emp_id = this.props.employee?.employee_id ? this.props.employee?.employee_id : this.props.employee?.id;
    // let role_id = this.props.employee?.role_id
    // let url = employees_and_projects + `?is_archive=0&emp_id=${emp_id}&role=${role_id}`
    let url = `/api/prp/role/get_emp_shdl_data/?subcontractor=${this.props.employee.id}`
    Service.get(url, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        this.setState({
          rawEmployeeSchedules: res.data,
          isLoading: false,
        }, () => {
          this.getLinkedRolesForWorkType()
        });
      })
      .catch((error) => {
        console.log(error)
        this.props.enqueueSnackbar("Getting employee projects failed!", {
          variant: "error",
        });
      });
  }

  addEmployeetoPRP() {
    var fields = ""
    if (!this.state.selectedCrm) {
      fields += "CRM"
    }

    if (!this.state.selectedRole) {
      fields += !fields ? "Role" : ', Role'
    }

    // if (!this.state.selectedDiscipline) {
    //   fields += !fields ? "Discipline" : ', Discipline'
    // }

    if (!fields) {
      let data = []
      let postData = {
        crm: this.state.selectedCrm.id,
        emp_id: this.props.employee.employee_id,
        role: this.state.selectedRole.id,
        // descipline: this.state.selectedDiscipline.id,
        shdl_data: [],
        dates: {}
      }
      data.push(postData)
      Service.post(conditionalPRPTandPRPRcreate, {
        headers: {
          Authorization: "Token " + token,
        },
        data
      }).then(res => {
        this.setState({ assignDialog: false, isLoading: false }, () => { this.props.unmountMe(); this.props.refreshData() });
        this.props.enqueueSnackbar('Assigned to a Project Successfully', {
          variant: 'success'
        });
      })
        .catch(error => {
          this.setState({ isLoading: false })
          this.props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
          });
        });
    } else {
      this.props.enqueueSnackbar(fields + ' is Empty!', {
        variant: 'warning'
      });
    }
  }

  componentDidMount() {
    // Gettting employees projects time schedules from backend
    Promise.all([
      this.getDiscipline(),
    ]).then((res) => this.setState({ disciplineArr: res[0] }))
    let startDate = moment().subtract(1, "month");
    let endDate = moment().add(11, "month");
    this.setWeekdates(startDate, endDate);
    this.getEmployeeProjects()

  }

  addResource(data) {
    this.setState({ isLoading: true })

    Service.post(prp_template_api, {
      headers: {
        Authorization: "Token " + token,
      },
      data,
    })
      .then(res => {
        this.addEmployee({
          prp: res.data.id, crm: data.crm,
          emp_id: this.props.employee.employee_id,
          // descipline: this.state.selectedDiscipline.id,
          shdl_data: [{ from: '', qty: '', to: '' }],
          dates: {}
        })
        this.setState({ assignDialog: false });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      });
  }

  addEmployee(data) {
    Service.post(prp_roles_api, {
      headers: {
        Authorization: "Token " + token,
      },
      data: data,
    })
      .then(res => {
        this.setState({ isLoading: false, }, () => this.props.unmountMe())
        this.props.enqueueSnackbar('Project added successfully', {
          variant: 'success'
        });
        return
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
        return
      })

  }

  deletedSchedules() {
    Service.delete(`/api/role/schedule/delete_shdl_data/?id=${this.state.deletedSchedules}`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        this.applyQuantityToEveryWeek()
        this.setState({ deletedSchedules: [] })
      })
      .catch(error => {
        console.log(error)
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      });
  }

  updateSchedules() {
    if (this.state.deletedSchedules.length === 0) {
      this.applyQuantityToEveryWeek()
    } else {
      this.deletedSchedules()
    }
  }

  getLinkedRolesForWorkType() {
    Service.get('/api/role/worktype/role_work_type_link/', {
      headers: {
        Authorization: "Token " + token,
      },
    }).then(res => {
      let obj = []
      for (let index = 0; index < res.data.length; index++) {
        const element = res.data[index];
        if (element.linked_role_ids.length !== 0) {
          for (let index = 0; index < element.linked_role_ids.length; index++) {
            const element1 = element.linked_role_ids[index];
            if (element1.role_id === this.props.employee.role_id) {
              obj.push(element)
            }
          }
        }
      }
      this.setState({ workType: obj })
    }).catch(error => {
      console.log(error)
      this.props.enqueueSnackbar('Something went wrong!', {
        variant: 'error'
      });
    })
  }

  render() {
    return (
      <div>
        <Dialog
          open={true}
          onClose={() => { this.props.unmountMe(); }}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={'sm'} >
          <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#AFD6CB" }}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {this.props.employee.name+ "'s Projects Schedules"}
            </Typography>
            {/* <IconButton
              color="inherit"
              aria-label="add"
              onClick={() => {
                if (this.props.addAllocation) {
                  this.props.addAllocation(this.props.employee, null)
                }
              }}
              style={{ position: "absolute", top: "5px", right: "20px" }}>
              <Tooltip title='Add to CRM'>
                <Add />
              </Tooltip>
            </IconButton> */}
          </DialogTitle>

          <DialogContent>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems="center" width={'100%'} marginBottom={'10px'}>
              <Box marginRight={'20px'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={"from"}
                    label="From"
                    // minDate={this.props.project.contractor_start_date}
                    // maxDate={this.props.project.contractor_end_date}
                    value={this.state.chartDuration[0]}
                    onChange={(date) => {
                      if (moment(date).day() !== 1) {
                        this.props.enqueueSnackbar(
                          "You are allowed to select only Mondays!!!",
                          {
                            variant: "error",
                          }
                        );
                      } else {
                        let chrtRange = [...this.state.chartDuration];
                        chrtRange[0] = moment(date);
                        this.setState({ chartDuration: chrtRange });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: 150 }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box marginRight={'20px'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={"to"}
                    label="To"
                    // minDate={this.props.project.contractor_start_date}
                    // maxDate={this.props.project.contractor_end_date}
                    value={this.state.chartDuration[1]}
                    onChange={(date) => {
                      if (moment(date).day() !== 1) {
                        this.props.enqueueSnackbar(
                          "You are allowed to select only Mondays!!!",
                          {
                            variant: "error",
                          }
                        );
                      } else {
                        let chrtRange = [...this.state.chartDuration];
                        chrtRange[1] = moment(date);
                        this.setState({ chartDuration: chrtRange });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    style={{ width: 150 }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.generateGraphData(this.state.rawEmployeeSchedules);
                    this.setState({ showChart: true });
                  }}>
                  Get Chart
                </Button>
              </Box>
            </Box>

            <Divider style={{ marginBottom: '10px' }} />
            
            {this.state.isLoading ?
              <Box display={'flex'} margin={2} flexDirection={'row'} justifyContent={'center'} >
                <CircularProgress color="primary" />
              </Box> :
              this.state.rawEmployeeSchedules.map((project, i) => (
                <Box display={'flex'} flexDirection={'column'} mb={1} key={i}>
                  <Box>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      {project.crm_name ? project.crm_name : ""}
                    </Typography>
                    {/* <IconButton
                      size="small"
                      aria-label="add"
                      onClick={() => {
                        if (this.props.addAllocation) {
                          this.props.addAllocation(this.props.employee, project)
                        }
                      }}  >
                      <Tooltip title='Add Schedule'>
                        <AddCircleOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </IconButton> */}
                  </Box>

                  <Box>
                    {project.shdl_data.map((schedule, j) => (
                      <Grid container spacing={1} key={j} direction="row" justifyContent="center" alignItems={'flex-end'} style={{ marginTop: '5px', marginBottom: '5px' }}>
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            aria-label="remove"
                            // style={{ marginTop: "10px" }}
                            onClick={(e) =>
                              this.removeSchedule(i, j)
                            }>
                            <RemoveIcom fontSize="small" />
                          </IconButton>
                        </Grid>

                        <Grid item xs={this.state.workType.length === 0 ? 3 : 2}>
                          <Box style={{ fontSize: "10px", color: '#737373' }}>Role</Box>
                          <Box fontSize={'10px'} display={'flex'} color={'#4d4d4d'} flexDirection={'row'} justifyContent={'left'} style={{ boxSizing: 'border-box', border: '1px solid #4d4d4d', borderRadius: '5px', height: '100%', width: '100%', padding: '5px' }}>
                            {schedule.role_name}
                          </Box>
                        </Grid>

                        {this.state.workType.length === 0 ? null :
                          <Grid item xs={2}>
                            <TextField
                              fullWidth
                              id="work_type"
                              // margin="dense"
                              value={schedule.work_type}
                              label={"Work Type"}
                              select
                              size='small'
                              onChange={(e) => this.handleWorkType(e, i, j)}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null
                              }}>
                              {this.state.workType.map((ele, k) => (
                                <MenuItem value={ele.id} key={k}>
                                  {ele.name + (!ele.short_form ? '' : ` (${ele.short_form})`)}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>}

                        <Grid item xs={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              // margin="normal"
                              id={"from"}
                              label="From"
                              value={schedule.from ? schedule.from : null}
                              onChange={(date) => {
                                this.handleFromDate(date, i, j)
                              }}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              style={{ width: 130 }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              // margin="normal"
                              id={"to"}
                              label="To"
                              value={schedule.to ? schedule.to : null}
                              onChange={(date) =>
                                this.handleToDate(date, i, j)
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              style={{ width: 130 }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={this.state.workType.length === 0 ? 2 : 1}>
                          <TextField
                            id={"qty"}
                            label="Qty"
                            type="number"
                            fullWidth
                            // margin="dense"
                            value={schedule.qty ? schedule.qty : null}
                            onChange={(e) =>
                              this.handleQty(e, i, j)
                            }
                            onBlur={() => this.handleQtyCheck(i, j)}
                          />
                        </Grid>

                      </Grid>
                    ))}
                  </Box>
                </Box>
              ))}
          </DialogContent>

          <DialogActions>
            <Button variant="contained" size="small" onClick={() => { this.props.unmountMe(); }} color="secondary">
              Cancel
            </Button>
            <Button variant="contained" size="small" color="primary" onClick={() => this.updateSchedules()}>
              Apply
            </Button>
          </DialogActions>
        </Dialog >

        <Dialog
          maxWidth={"xl"}
          fullWidth={true}
          open={this.state.showChart}
          onClose={() => {
            this.setState({ series: [], showChart: false });
          }}>
          <DialogTitle id="form-dialog-title" style={{ backgroundColor: "#e6f9ff" }} >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {"All Schedules"}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={"from"}
                    label="From"
                    // minDate={this.props.project.contractor_start_date}
                    // maxDate={this.props.project.contractor_end_date}
                    value={this.state.chartDuration[0]}
                    onChange={(date) => {
                      if (moment(date).day() !== 1) {
                        this.props.enqueueSnackbar(
                          "You are allowed to select only Mondays!!!",
                          {
                            variant: "error",
                          }
                        );
                      } else {
                        let chrtRange = [...this.state.chartDuration];
                        chrtRange[0] = moment(date);
                        this.setState({ chartDuration: chrtRange });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={this.props.classes.date}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id={"to"}
                    label="To"
                    // minDate={this.props.project.contractor_start_date}
                    // maxDate={this.props.project.contractor_end_date}
                    value={this.state.chartDuration[1]}
                    onChange={(date) => {
                      if (moment(date).day() !== 1) {
                        this.props.enqueueSnackbar(
                          "You are allowed to select only Mondays!!!",
                          {
                            variant: "error",
                          }
                        );
                      } else {
                        let chrtRange = [...this.state.chartDuration];
                        chrtRange[1] = moment(date);
                        this.setState({ chartDuration: chrtRange });
                      }
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className={this.props.classes.date}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState({ graphRerender: false }, () => {
                      this.generateGraphData(this.state.rawEmployeeSchedules);
                    });
                  }}
                >
                  {"Get Chart"}
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>

          {this.state.graphRerender ? (
            <SubContractorProjectsSchedulesGraph
              dateLabels={this.state.dateLabels}
              gseries={this.state.series}
            />
          ) : null}
        </Dialog>

        <Dialog open={this.state.assignDialog} onClose={() => this.setState({ assignDialog: false })}>
          <DialogTitle id="form-dialog-title"
            style={{ backgroundColor: "#e6f9ff" }}>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {"Assign " + this.props.employee.first_name +
                " " +
                this.props.employee.last_name +
                " to a Project"}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Autocomplete
              id="crm"
              style={{ marginBottom: '1rem' }}
              autoComplete
              fullWidth
              options={this.props.allProjects}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => this.setState({ selectedCrm: value })}
              value={this.state.selectedCrm}
              size="medium"
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="CRM" placeholder="CRM" />
              )}
            />
            <Autocomplete
              id="role"
              fullWidth
              autoComplete
              options={this.props.allRoles}
              style={{ marginBottom: '1rem' }}
              getOptionLabel={(option) => option.job_roles}
              onChange={(e, value) => this.setState({ selectedRole: value })}
              value={this.state.selectedRole}
              size="medium"
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.job_roles}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Role" placeholder="Role" />
              )}
            />
          </DialogContent>
          < DialogActions >
            <Button
              onClick={() => {
                this.setState({ assignDialog: false })
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => this.addEmployeetoPRP()}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withSnackbar(withStyles(useStyles)(SubContractorProjectsSchedules));

