import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { Component } from 'react'
import AllocateSchedulesforRoles from './AllocateSchedulesforRoles';
import { withSnackbar } from 'notistack';
import Service from '../../config/networkutils';
import { getCookie } from '../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");


class SplitDemandRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            roleGroups: [],
            selectedRole: null,
            addRoleDisabled: true,
            roles: this.props.roles,
            mainMenu: true,
            schedules: [],
            displaySchedules: true,
            buttonDisabled: false,
        }
        this.backToMainMenu = this.backToMainMenu.bind(this)
        this.refreshData = this.refreshData.bind(this) 
    }

    submit = () => {
        if (this.state.selectedRole) {
            this.setState({ mainMenu: false })
            this.props.increaseDialogSize()
        } else {
            this.props.enqueueSnackbar("Please select a role", {
                variant: 'warning'
            });
        }
    }

    backToMainMenu = () => {
        this.props.reduceDialogSize()
        this.setState({ mainMenu: true })
    }

    componentDidMount() {
        console.log(this.props)
    }

    fetchSchedule = () => {
        if (!this.state.selectedRole) {
            this.props.enqueueSnackbar("Please select a role", {
                variant: 'warning'
            });
            return
        }
        else if (this.props.user.role_id === this.state.selectedRole.id) {
            this.props.enqueueSnackbar("User already has this role", {
                variant: 'warning'
            });
            return
        } else {
            this.setState({ buttonDisabled: true })
            Service.get(`/api/prp/rolenew/get_unassigned_shdl/?crm=${this.props.crm.crm_id}&prp_temp=${this.props.user.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    if (res.data.length === 0) {
                        this.props.enqueueSnackbar("No schedules available for this role", {
                            variant: 'warning'
                        });
                        this.setState({ buttonDisabled: false })
                        return
                    } else {
                        let data = res.data
                        for (let i = 0; i < data.length; i++) {
                            const element = data[i];
                            element.isSelected = false
                            for (let j = 0; j < element.shdl_data.length; j++) {
                                const element1 = element.shdl_data[j];
                                element1.isSelected = false
                                element1.updated_qty = element1.qty
                            }
                        }
                        this.setState({
                            schedules: data.sort((a, b) => b.shdl_data.length - a.shdl_data.length),
                            buttonDisabled: false,
                            mainMenu: false,
                            isLoading: false
                        }, () => {
                            this.props.increaseDialogSize()
                            if ((this.state.schedules.length > 0) && (this.props.increaseDialogSize)) {

                            }
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.setState({ buttonDisabled: false })
                });
        }
    }

    refreshData = () => {
        this.setState({
            isLoading: true
        }, () => {
            this.fetchSchedule()

        })
    }


    render() {
        return (
            <div>
                {this.state.mainMenu ?
                    <Box>
                        <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={this.state.roles}
                                getOptionLabel={(option) => option.job_roles}
                                value={this.state.selectedRole}
                                style={{ width: '350px' }}
                                onChange={(e, value) => this.setState({ selectedRole: value })}
                                renderInput={(params) => <TextField {...params} label="Role" variant="outlined" />} />
                        </Box>
                        <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={1}>
                            <Button disabled={this.state.buttonDisabled} color='primary' variant='contained' onClick={() => this.fetchSchedule()}>
                                Allocate
                            </Button>
                        </Box>
                    </Box> :
                    <Box>
                        {this.state.isLoading ? <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress /></Box> :
                            <AllocateSchedulesforRoles
                                subcontractor={false}
                                role={this.props.role}
                                selectedRole={this.state.selectedRole}
                                project={this.props.project}
                                crm={this.props.crm}
                                user={this.props.user}
                                // allocationData={this.props.allocationData}
                                closeDialog={this.props.closeDialog}
                                backToMainMenu={this.backToMainMenu}
                                refreshData={this.refreshData}
                                schedules={this.state.schedules}
                                // getMenu={this.getMenu}
                                // crm={this.state.selectedCrm}
                                // user={this.state.selectedUser}
                                // closeDialog={this.closeDialog}
                                // refreshData={this.refreshData}
                                allocationData={this.props.allocationData}
                            />
                        }
                    </Box>}
            </div>
        )
    }
}

export default withSnackbar(SplitDemandRole);
