import { withSnackbar } from 'notistack';
import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LinkLineManager from './LinkLineManager';
import UserAsLM from './UserAsLM';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class LineManagerTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        }
        this.reloadEmployeeData = this.reloadEmployeeData.bind(this)
    }

    reloadEmployeeData() {
        if (this.props.reloadEmployeeData) {
            this.props.reloadEmployeeData(this.state.tabValue === 0 ? true : false)
        }
    }

    render() {

        const handleChange = (event, newValue) => {
            this.setState({ tabValue: newValue })
        };
        return (
            <div>
                <Tabs
                    value={this.state.tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}>
                    <Tab label="Link LineManager" {...a11yProps(0)} />
                    <Tab label="User As LineManager" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={this.state.tabValue} index={0}>
                    <LinkLineManager
                        userData={this.props.userData}
                        employeeData={this.props.employeeData}
                        selectedLineManager={this.props.selectedLineManager}
                        reloadEmployeeData={this.reloadEmployeeData}
                    />
                </TabPanel>
                <TabPanel value={this.state.tabValue} index={1}>
                    <UserAsLM
                        userData={this.props.userData}
                        employeeData={this.props.employeeData}
                        selectedLineManager={this.props.selectedLineManager}
                        reloadEmployeeData={this.reloadEmployeeData}
                    />
                </TabPanel>
            </div>
        );
    }
}

export default withSnackbar(LineManagerTabs);