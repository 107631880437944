import React, { Component } from 'react'
import { getCookieNoDecrypt } from '../../utils/CookieHelper';
import { getClientID } from '../../utils/reuseFunctions';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Box } from '@material-ui/core';

class MyLeaves extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            employeeData: [],
        }
    }

    getData = () => {
        Service.get(`/api/app/employee/get_emp_leave_requests/`, {
            headers: {
                Authorization: getCookieNoDecrypt('msal.' + getClientID() + '.idtoken'),
            },
        }).then(res => {
            this.setState({ data: res.data, isLoading: false }, () => {
            })
        })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' })
            })
    }

    componentDidMount() {
        this.getData()
    }


    render() {
        return (
            <div>
                <Box margin={2}>
                    <MaterialTable
                        columns={[{
                            title: 'Category', field: 'other_tasks_name',
                        }, {
                            title: 'Date', field: 'date',
                            render: rowData => moment(rowData.date).format('DD-MM-YYYY')
                        }, {
                            title: 'Qty', field: 'quantity',
                        }, {
                            title: 'Comments', field: 'comments',
                        }, {
                            title: 'Status', field: 'status',
                            lookup: { 0: 'Planned', 1: 'Pending', 2: 'Approved', 3: 'Rejected', 4: 'Deleted', 5: 'Admin Approved' }
                        }
                        ]}
                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        title="Leave Requests"
                        options={{
                            paging: false,
                            fontSize: '7',
                        }}
                    />
                </Box>
            </div>
        )
    }
}

export default withSnackbar(MyLeaves);
