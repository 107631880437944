import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from '../components/Home/Home';
import Login from '../components/Login/Login';
import PrivateRoute from './PrivateRoute';
import Auth from '../utils/authenticate';
import EmployeeRoute from './EmployeeRoute';
import MobileViewDrawer from '../components/MobileView/MobileViewDrawer';

function Routes() {
    return (<>
        <Switch>
        <Route exact path="/login" render={() => Auth.isAuthenticated() && Auth.user() ? <Redirect to="/" /> : <Login />} />
       <EmployeeRoute path='/group/user'  component={MobileViewDrawer} />
        <PrivateRoute path='/' component={Home} />
        
        {/* <Route path="/graph" render={() => <GangDashboardGraph gang={1} />} /> */}
         {/* <Route path="/graph">
                <GraphComponent /> 
        </Route> */}
        </Switch>
    </>
    )
}

export default Routes