import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import { withSnackbar } from "notistack";

import { DialogContent } from "@material-ui/core";

import Chart from "react-apexcharts";

const useStyles = (theme) => ({
  textFieldContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  itemMargin: {
    margin: "10px",
  },
  appBarMargin: {
    // marginTop: "70px",
    marginTop: "0px",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    float: "right",
  },
  customGridStyle: {
    margin: 0,
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
  },
  date: {
    margin: 0,
    width: "100%",
    "margin-top": "5px",
  },
});

class SubContractorProjectsSchedulesGraph extends Component {


  state = {
    options: {
      chart: {
        stacked: false,
        height: 350,
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
          zoomedArea: {
            fill: {
              color: "#90CAF9",
              opacity: 0.4,
            },
            stroke: {
              color: "#0D47A1",
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      xaxis: {
        type: "category",
        categories: this.props.dateLabels,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          offsetY: 10,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
        title: {
          text: "Weeks",
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,

        labels: {
          show: true,
          align: "left",
          minWidth: 0,
          maxWidth: 160,
          offsetX: -10,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      title: {
        text: "Schedules",
        rotate: -90,

        style: {
          color: undefined,
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-title",
        },
      },

      stroke: {
        show: true,
        curve: "straight",
        // lineCap: "butt",
        colors: undefined,
        width: 3,
        dashArray: 0,
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    },
  };


  render() {
    return (
      <DialogContent>
        <Chart
          options={this.state.options}
          series={this.props.gseries}
          height={"400px"}
          width={"90%"}
          type={"area"}
        />
      </DialogContent>
    );
  }
}

export default withSnackbar(
  withStyles(useStyles)(SubContractorProjectsSchedulesGraph)
);
